import React, { useEffect, useContext, useState } from 'react';
import { GlobalContext } from '../../../3_context/a_GlobalContext';
import { fetchSearchResultsWithinNeighborhood } from '../../../5_services/a_Algolia/8_FetchSearchResultsWithinNeighborhood';
import { fetchSearchResultsWithinRadius } from '../../../5_services/a_Algolia/9_FetchSearchResultsWithinRadius';
import Item from '../../../2_common/i_Item';
import styles from './b_SearchResults.module.css';
import { searchResultsPerPage } from '../../../App';
import Pagination from '../../../2_common/m_Pagination';
import { useSearchParams } from 'react-router-dom';
import { Scroll } from '../../../4_utils/h_ScrollToTop';
import { trackEvent } from '../../../4_utils/i_GoogleAnalytics';

// testing merge

function SearchResults( ) {
  
  const { searchResults, setSearchResults } = useContext(GlobalContext);
  const { query } = useContext(GlobalContext);
  const { setStoreFilters, storeFilters, categoryFilters } = useContext(GlobalContext);
  const { initialSearch } = useContext(GlobalContext);

  const { currentLocationType } = useContext(GlobalContext);
  const { currentDistance } = useContext(GlobalContext);
  const { currentNeighborhood } = useContext(GlobalContext);
  const { userCoordinates } = useContext(GlobalContext);
  const { searchResultsCount, setSearchResultsCount } = useContext(GlobalContext);
  const { searchResultsPage, setSearchResultsPage } = useContext(GlobalContext);
  const { saleFilter } = useContext(GlobalContext);
  const { newArrivalFilter } = useContext(GlobalContext);
  const { deliverableFilter } = useContext(GlobalContext);
  const { sort } = useContext(GlobalContext);
  const [showNoResultsMessage, setShowNoResultsMessage] = useState(false);
  const [noResultsTimeout, setNoResultsTimeout] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  // To Stop Search From Being Run Twice Upon Location Change

  let counter = 0;
  useEffect(() => {
    async function performSearch() {
      counter++;
      console.log("On performSearch: ", counter)
      try {
        let results;
        if (currentLocationType === "Current Location" && userCoordinates) {
          results = await fetchSearchResultsWithinRadius(query, userCoordinates, currentDistance, storeFilters, categoryFilters, saleFilter, newArrivalFilter, deliverableFilter, searchResultsPage, sort);
        } else {
          results = await fetchSearchResultsWithinNeighborhood(query, currentNeighborhood, storeFilters, categoryFilters, saleFilter, newArrivalFilter, deliverableFilter, searchResultsPage, sort);
        }

        setSearchResults(results.items);
        setSearchResultsCount(results.total);

        // Tracking store results for Google Analytics
        if (results.items && results.items.length > 0) {
          const uniqueStores = new Set();
          results.items.forEach(item => uniqueStores.add(item.store));
          console.log(`Unique Stores:`, uniqueStores)
          
          uniqueStores.forEach(store => {
            trackEvent('search_surface', {
              'event_category': 'Unique Store Results',
              'store_name': store,
              'search_query': query,
              'value':1,
              'send_to': 'G-W17DEQW0DE'
            });
          });
        }

      } catch (error) {
        console.error('Error fetching search results:', error);
      }
    }
    performSearch();

    // eslint-disable-next-line
  }, [query, storeFilters, categoryFilters, saleFilter, newArrivalFilter, deliverableFilter, currentDistance, currentNeighborhood, searchResultsPage, sort]);


// ADD A DELAY BEFORE SHOWING NO RESULTS SO IT DOESN'T FLASH
  useEffect(() => {
    if (initialSearch && !hasResults) {
      const timeout = setTimeout(() => {
        setShowNoResultsMessage(true);
      }, 500);
      setNoResultsTimeout(timeout);
    } else {
      setShowNoResultsMessage(false);
      if (noResultsTimeout) {
        clearTimeout(noResultsTimeout);
      }
    }
    return () => {
      if (noResultsTimeout) {
        clearTimeout(noResultsTimeout);
      }
    }
    // eslint-disable-next-line
  }, [searchResults, initialSearch]);
  
  // Scroll to top upon page change anywhere
  useEffect(() => {
    if (searchResultsPage !== undefined) {
      Scroll();
    }
  }, [searchResultsPage]);


  const hasResults = searchResults.length > 0;
  const handlePageChange = (newPage) => {
    if (hasResults && Math.ceil(searchResultsCount / searchResultsPerPage) > 1) {
      searchParams.set('page', String(newPage+1));
      setSearchParams(searchParams);
      setSearchResultsPage(newPage);
    }

    Scroll();
  }

  return (
    <div className={styles.container}>
      {/* Pagination above the items */}
      {hasResults && Math.ceil(searchResultsCount / searchResultsPerPage) > 1 && (
          <div className={styles.banner}>
          <div className={styles.searchresultscount}>
            {searchResultsCount} hits
          </div>
      <Pagination
        currentPage={searchResultsPage}
        totalPages={Math.ceil(searchResultsCount / searchResultsPerPage)}
        handlePageChange={handlePageChange}
      />
    </div>
  )}

      {/* Render items */}
      <div className={styles.items}>
        {hasResults
          ? searchResults.map((item, index) => <Item item={item} key={index} setStoreFilters={setStoreFilters} />)
          : showNoResultsMessage && (
              <div className={styles.noresults}>
                We found no results for your search
                <p className={styles.noresultssubtext}>Email request@shopcharm.co and we'll tell you which store near you carries the item you need</p>
              </div>
            )}
      </div>

      {/* Pagination below the items */}
      {hasResults && Math.ceil(searchResultsCount / searchResultsPerPage) > 1 && (
        <div className={styles.banner}>
          <div className={styles.searchresultscount}>
            {searchResultsCount} hits
          </div>
          <Pagination
            currentPage={searchResultsPage}
            totalPages={Math.ceil(searchResultsCount / searchResultsPerPage)}
            handlePageChange={handlePageChange}
          />
        </div>
      )}
    </div>
  );
}

export default SearchResults;
