// WelcomeWidget.js
import React, { useContext, useState, useEffect } from 'react';
import styles from './h_WelcomeWidget.module.css'; // Ensure this is the correct path to your CSS module
import { GlobalContext } from '../../../3_context/a_GlobalContext'; // Adjust according to your file structure
import { storeBlockImages } from '../../../App'; // Verify this is where your images are defined
import StoreBlock from './i_StoreBlock'; // Update './i_StoreBlock' to the correct path where StoreBlock is defined
import play_icon from '../../../7_icons/play_icon.svg';
import pause_icon from '../../../7_icons/pause_icon.svg';
import { useSearchParams } from 'react-router-dom';
import { Scroll } from '../../../4_utils/h_ScrollToTop';

function WelcomeWidget({ setShopNow }) {
    const { setStoreFilters } = useContext(GlobalContext);
    const [isPaused, setIsPaused] = useState(false); 
    let [ searchParams, setSearchParams] = useSearchParams();
    

    useEffect(() => {
        const handleResize = () => {
            setCurrentChunks(window.innerWidth > 800 ? storeChunks : storeChunksMobile);
        };

        // Set up event listener
        window.addEventListener('resize', handleResize);

        // Clean up event listener
        return () => window.removeEventListener('resize', handleResize);
        // eslint-disable-next-line
    }, []); // Empty dependency array ensures this effect runs only once at mount

    const handleStoreClick = (storeName) => {
        setStoreFilters(prevFilters => {
            return prevFilters.includes(storeName) ? prevFilters : [...prevFilters, storeName];
        });
        searchParams.set('stores', storeName);
        searchParams.delete('page'); // Remove page parameter, if you have pagination
        searchParams.delete('categories'); // Remove page parameter, if you have pagination
        searchParams.delete('query'); // Remove page parameter, if you have pagination
        setSearchParams(searchParams);
        Scroll()
    };

    const handleShopNowClick = () => {
        setShopNow(false);
    };

    const togglePause = () => {
        setIsPaused(!isPaused); // Toggle the pause state
    };

    // Manually define store chunks
    const storeChunks = [
        [ // First chunk
            "Gaslight & Shadows",
            "Mai Do",
            "Variety Shop",
            "Olive",
            "Arguello Salon",
            "Clement Nursery",
            "Love Street Vintage",
            "Sparky's Balloons",
            "Fela's Discount",
            "Geary Shoe Repair"
            
        ],
        [ // Second chunk
            "Electric Bike Center",
            "Ornot",
            "Aqua Forest Aquarium",
            "The Drawing Room",
            "JVD Global",
            "6th Avenue Aquarium",
            "Wood Goods & Hot Sauce",
            "Food 24 Market",
            "Eye & Hand Society",
            "The Future Past",
            "Aladdin Rugs",
            "DD Cycles",
        ],
        [ // Third chunk
            "Uncommon Thrifts",
            "Goldstar Discount",
            "Gable's Stationary",
            "Trending Houseware",
            "ENGRAFFT",
            "Foggy Notion",
            "Paloma",
            "Kinokuniya",
            "Finer Flora",
            "Wishing Well Workshop",
            "JANE Consignment",
           
        ]
    ];

    const storeChunksMobile = [
        [ // First chunk
            "Gaslight & Shadows",
            "Mai Do",
            "Variety Shop",
            "Gable's Stationary",
            "Olive",
            "Arguello Salon",
            "Clement Nursery",
            "Paloma",
            "Kinokuniya",
            "Finer Flora",
            "Wishing Well Workshop",
            "Love Street Vintage",
            "Sparky's Balloons",
            "Fela's Discount",
            "Geary Shoe Repair",
            "Uncommon Thrifts",
            
        ],
        [ // Second chunk
            "Electric Bike Center",
            "JANE Consignment",
            "The Future Past",
            "Aladdin Rugs",
            "DD Cycles",
            "Aqua Forest Aquarium",
            "JVD Global",
            "6th Avenue Aquarium",
            "Wood Goods & Hot Sauce",
            "Food 24 Market",
            "Trending Houseware",
            "Goldstar Discount",
            "Eye & Hand Society",
            "The Drawing Room",
            "ENGRAFFT",
            "Ornot",
            "Foggy Notion",
        ],
    ];
    const [currentChunks, setCurrentChunks] = useState(window.innerWidth > 800 ? storeChunks : storeChunksMobile);

    // Define background colors for the blocks
    const backgroundColors = ['#0FC4A6', '#DD4E4E', '#B084CC'];



    return (
        <div className={styles.container}>
            <div className={styles.leftcontent}>
                <div className={styles.delivery}>Find online, buy in-store</div>
                <div className={styles.title}>Find local gems from unique storefronts</div>
                <div className={styles.shopnow} onClick={handleShopNowClick}>Shop Now</div>
            </div>
            <div className={styles.rightcontent}  onTouchStart={() => setIsPaused(true)} onTouchEnd={() => setIsPaused(false)} onMouseEnter={() => setIsPaused(true)} onMouseLeave={() => setIsPaused(false)}>
                {currentChunks.map((chunk, chunkIndex) => (
                    <div key={chunkIndex} className={`${styles.teststrip}`} style={{animationPlayState: isPaused ? 'paused' : 'running'}}>
                        {chunk.map((storeName, index) => (
                            <StoreBlock 
                                key={storeName} 
                                storeName={storeName} 
                                storeBlockImage={storeBlockImages[storeName]} 
                                style={{ backgroundColor: backgroundColors[index % backgroundColors.length] }}
                                onClick={() => handleStoreClick(storeName)} // Pass onClick handler
                            />
                        ))}
                    </div>
                ))}
            </div>
            <div className={styles.shopnowmobile} onClick={handleShopNowClick}>Shop Now</div>
            <img src={isPaused ? play_icon : pause_icon} className={styles.pausePlayIcon} onClick={togglePause} alt="Pause/Play"/>
        </div>
    );
}

export default WelcomeWidget;