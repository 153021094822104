import React, {useRef} from 'react';
import styles from './t_PrivacyPolicyOverlay.module.css';
import close_icon from '../7_icons/close_icon.svg';
import { useOnClickOutside } from '../4_utils/g_CloseOverlay';

const PrivacyPolicyOverlay = ({ setShowPrivacyPolicy, showPrivacyPolicy }) => {
    
    const aboutRef = useRef(null); 
    useOnClickOutside(aboutRef, () => setShowPrivacyPolicy(false));

    const closePrivacyPolicy = () => {
        setShowPrivacyPolicy(false);
    };

    return (
        <div ref={aboutRef} className={`${styles.container} ${showPrivacyPolicy ? styles.active : ''}`}>
            <i className={styles.close} onClick={closePrivacyPolicy}>
                <img src={close_icon} alt="close"/>
            </i>
            <div className={styles.title}> Privacy Policy</div>
            <div className={styles.divider} />
            <div>
                <div className={styles.instruction}><strong>Infomation we Collect</strong></div>
                <div className={styles.instruction}>At Charm, we prioritize transparency and privacy. Directly, our website does not collect or store cookies that gather personal information from our visitors. However, we utilize Hotjar and Google Analytics, third-party analytics tools, to understand and improve user experience on our site.</div>
                <div className={styles.divider} />
                <div className={styles.instruction}><strong>Use of Tracking Technologies</strong></div>
                <div className={styles.instruction}>Google Analytics– we use Google Analytics to understand how our website is accessed and used. Google Analytics collects data about your visits to our website, including the web pages you view, the website that referred you to us, and other actions you take on our site. This information helps inform our partner stores about activity regarding their products. For more information on how Google uses this data, visit Google's Privacy & Terms.</div>
                <div className={styles.instruction}>Hotjar– Hotjar helps us to understand your experience on our website by tracking your actions, such as clicks and movements on our pages. This information is anonymous and designed to help us improve website functionality. Hotjar is contractually forbidden from selling collected data. For more information on Hotjar, visit Hotjar’s Privacy Policy.</div>
                <div className={styles.divider} />
                <div className={styles.instruction}><strong>Your Data Protection Rights Under CCPA</strong></div>
                <div className={styles.instruction}>The CCPA provides consumers (California residents) with specific rights regarding their personal information. If you wish to exercise any of these rights, please contact us using the contact information provided below.</div>
                <div className={styles.divider} />
                <div className={styles.instruction}><strong>Changes to This Privacy Policy</strong></div>
                <div className={styles.instruction}>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</div>
                <div className={styles.divider} />
                <div className={styles.instruction}><strong>privacy@shopcharm.co</strong></div>
                <div className={styles.instruction}>If you have any questions about this Privacy Policy, please contact us.</div>
            </div>

        </div>
    );
};

export default PrivacyPolicyOverlay;