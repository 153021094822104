import algoliasearch from 'algoliasearch/lite';
import { daysSinceArrival } from '../../App';


const algoliaClient = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID, process.env.REACT_APP_ALGOLIA_API_KEY_MAIN);
const index = algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_INDEX_NAME_MAIN);

let counter = 0;

export async function fetchNewArrivals(availableStores, resultsPerPage) {
  counter ++;
  console.log("fetchNewArrivals " + counter)
  try {
    const daysAgo = Math.floor(Date.now() / 1000) - (daysSinceArrival * 24 * 60 * 60);
    const availableStoresFilter = availableStores.map(store => `store:${store}`);
    const response = await index.search('', {
      numericFilters: [`timestamp>${daysAgo}`],
      facetFilters: [availableStoresFilter],
      hitsPerPage: resultsPerPage,
    });

    return { items: response.hits, total: response.nbHits };
  } catch (error) {
    console.error(`Error fetching New Arrivals`, error);
    return { items: [], total: 0 };
  }
}
