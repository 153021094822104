import React, {useState, useContext, useRef, useEffect} from 'react';
import DropdownMenu from './c_DropdownMenu';
import Slider from './e_Slider';
import search_icon from '../7_icons/search_icon.svg';
import largesearch_icon from '../7_icons/largesearch_icon.svg';
import location_icon from '../7_icons/location_icon.svg';
import sort_icon from '../7_icons/sort_icon.svg';
import styles from './x_TopBanner.module.css';
import { GlobalContext } from '../3_context/a_GlobalContext';
import { availableNeighborhoods } from '../App';
import { useSearchParams } from 'react-router-dom';
import { Scroll } from '../4_utils/h_ScrollToTop';
import logo_icon from '../7_icons/logo_icon.svg';
import filter_icon from '../7_icons/filter_icon.svg';
import dropdown_icon from '../7_icons/dropdown_icon.svg';
import Filters from './y_Filters';
import { useNavigate, useLocation } from 'react-router-dom';




function TopBanner() {
  
  let [searchParams, setSearchParams] = useSearchParams();
  const { currentLocation, 
          setCurrentLocation,
          setShowFilters,
          showFilters,
          showTopBannerSearchBar,
          setShowTopBannerSearchBar,
          isMobile,
          setCurrentNeighborhood,
          userCoordinates,
          currentDistance,
          setCurrentDistance,
          sort,
          setSort,
          initialQuery,
          initialSearch,
          setQuery
        } = useContext(GlobalContext);



  const [ locationDropdownOpen, setLocationDropdownOpen ] = useState(false);
  const [ sortDropdownOpen, setSortDropdownOpen ] = useState(false);
  const [ searchBarValue, setSearchBarValue ] = useState(initialQuery || '');
  const inputRef = useRef(null); 
  const navigate = useNavigate();
  const location = useLocation();

 
  
  // Resets search bar value on url change, reload, home page, etc.
  useEffect(() => {
    const urlQuery = searchParams.get('query') || '';
    setSearchBarValue(urlQuery);
    if (urlQuery === '') {
      setQuery(''); // Reset the query in context if the URL query is empty
    }
  // eslint-disable-next-line
  }, [searchParams]);
  

  const handleSearch = () => {
    const trimmedValue = searchBarValue.trim();
  
    if (trimmedValue) {
      // If the search query is not empty, set the query context and URL parameter
      setQuery(trimmedValue);
      searchParams.set('query', trimmedValue);
      setSearchParams(searchParams);
    } else {
      // If the search query is empty, remove the 'query' parameter from the URL
      searchParams.delete('query');
      // Create a new URLSearchParams object without 'query' to clean up the URL
      const newSearchParams = new URLSearchParams(searchParams);
      setSearchParams(newSearchParams);
    }

    searchParams.delete('page');
    searchParams.delete('categories');
    searchParams.delete('stores');
    searchParams.delete('sale');
    searchParams.delete('newArrival');
    searchParams.delete('deliverable');
    
    const isNotBasePage = location.pathname !== "/";
    const savedLocation = sessionStorage.getItem('savedLocation')
    if (isNotBasePage) {
      // Only navigate back to the base URL if not on the base page
      const newSearchParams = new URLSearchParams(searchParams);
      if (savedLocation) {
        newSearchParams.set('location', savedLocation);
      }
      navigate('/?' + newSearchParams.toString());
    } else {
      // If on the base page, just update the search parameters without navigating
      setSearchParams(searchParams);
    }
  
    Scroll();
  };
  
  

  const handleLocationChange = (DropdownValue) => {
    setCurrentLocation(DropdownValue);
    setCurrentDistance(3);
  
    if (DropdownValue === "Select Location") {
      setCurrentNeighborhood(null);
      window.location.href = '/';    }

    searchParams.set('location', DropdownValue);
    sessionStorage.setItem('savedLocation', DropdownValue);
    console.log(sessionStorage.getItem('savedLocation'))
    searchParams.delete('query');
    searchParams.delete('stores');
    searchParams.delete('categories');
    searchParams.delete('page');
    searchParams.delete('sale');
    searchParams.delete('newArrival');
    searchParams.delete('deliverable');
    const isNotBasePage = location.pathname !== "/";

    // Only navigate back to the base URL if not on the base page
    if (isNotBasePage) {
      // Prepare new search parameters for navigation
      const newSearchParams = new URLSearchParams(searchParams.toString());
      navigate('/?' + newSearchParams.toString());
    } else {
      // If on the base page, just update the search parameters
      setSearchParams(searchParams);
    }
  }



  const handleDistanceChange = (SliderValue) => {
    setCurrentDistance(SliderValue);  
  };

  const navigateToRoot = () => {
    sessionStorage.removeItem('savedLocation')
    window.location.href = '/';
  };
  
  return (
    <div className={styles.container}>
      <div className={styles.logotext} onClick={navigateToRoot}>
          Charm
        </div>
        
        
        

      <div className={styles.mobiletop}>
      <img src={logo_icon} className={styles.logoicon} alt="Logo" onClick={navigateToRoot} />
        <img className={styles.filtersiconmobile} src={filter_icon} alt="Menu" onClick={() => setShowFilters(prev => !prev)}/>
        <i className={styles.largesearchicon} onClick={() => setShowTopBannerSearchBar(prev => !prev)}>
            <img src={largesearch_icon} alt="SearchToggle" />
        </i>
        <div className={styles.filters} onClick={() => setShowFilters(prev => !prev)}>
          <img className={styles.filtersicon} src={filter_icon} alt="Menu" />
          <div className={styles.filterstext}>
            Filters
          </div>
          <i className={`${styles.dropdownicon} ${showFilters ? styles.flip : ''}`}>
            <img src={dropdown_icon} alt="dropdown" />
          </i>
        </div>
        <Filters/>

        
        <DropdownMenu 
          leftmostIcon={<img src={location_icon} alt="location"/>}
          currentValue={currentLocation}
          dropdownOpen={locationDropdownOpen}
          setDropdownOpen={setLocationDropdownOpen}
        >
          {/* <div className={styles.topoption} onClick={() => handleLocationChange("Current Location")}>Current Location</div> */}
          {availableNeighborhoods.map(neighborhood => (
            <div key={neighborhood} onClick={() => handleLocationChange(neighborhood)}>{neighborhood}</div>
          ))}
          <div className={styles.bottomoption} onClick={() => handleLocationChange("Select Location")}>All Locations</div>
        </DropdownMenu>
        {currentLocation === "Current Location" && userCoordinates && (
          <Slider
            label={"Distance"}
            min={0.5}
            max={5.5}
            increment={0.5}
            currentValue={currentDistance}
            onSliderChange={handleDistanceChange}
          />
        )}
        
      </div>
      
      

      <div className={`${styles.mobilebottom} ${isMobile && !showTopBannerSearchBar ? styles.toggle : ''}`}>
        <div className={styles.searchbarwrapper}>
          <input
            type="text"
            className={styles.searchbar}
            placeholder="Search"
            ref={inputRef}
            value={searchBarValue}
            onChange={e => setSearchBarValue(e.target.value)} // Add this line to update searchBarValue
            onKeyDown={e => e.key === 'Enter' && handleSearch()}
          />
          <i className={styles.searchicon} onClick={handleSearch}>
              <img src={search_icon} alt="Search" />
          </i>
        </div>
        {initialSearch && (
          <DropdownMenu 
          leftmostIcon={<img src={sort_icon} alt="sort"/>}
          currentValue={sort}
          dropdownOpen={sortDropdownOpen}
          setDropdownOpen={setSortDropdownOpen}
          >
            <div className={styles.topoption} onClick={() => { setSort("Sort"); Scroll(); }}>None</div>
            <div onClick={() => { setSort("Price: Low to High"); Scroll(); }}>Price: Low to High</div>
            <div onClick={() => { setSort("Price: High to Low"); Scroll(); }}>Price: High to Low</div>
          </DropdownMenu>
        )}
        
        
      </div>

      

      

      
   
  </div>
  );
}


export default TopBanner;
