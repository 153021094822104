import algoliasearch from 'algoliasearch/lite';


const algoliaClient = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID, process.env.REACT_APP_ALGOLIA_API_KEY_MAIN);
const index = algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_INDEX_NAME_MAIN);

let counter = 0;

export async function fetchSaleItems(availableStores, resultsPerPage, featureableStores) {
  counter ++;
  console.log("fetchSaleItems " + counter)
  try {

    const mainFilter = `${"isOnSale"}:${"true"}`;
    const availableStoresFilter = availableStores.map(store => `store:${store}`);
    const featureableStoresFilter = featureableStores.map(store => `store:${store}`);
    const facetFilters = [
      [mainFilter], // Ensures specific facet condition
      availableStoresFilter,
      featureableStoresFilter // All available stores - combined with OR
    ]; 

    const response = await index.search('', {
      facetFilters: facetFilters,
      hitsPerPage: resultsPerPage,
    });

    return { items: response.hits, total: response.nbHits };
  } catch (error) {
    console.error(`Error fetching New Arrivals`, error);
    return { items: [], total: 0 };
  }
}
