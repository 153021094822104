import React, {useRef} from 'react';
import styles from './zd_AboutUs.module.css';
import close_icon from '../7_icons/close_icon.svg';
import { useOnClickOutside } from '../4_utils/g_CloseOverlay';

const AboutUsOverlay = ({ setShowAboutUs, showAboutUs }) => {
    
    const aboutRef = useRef(null); 
    useOnClickOutside(aboutRef, () => setShowAboutUs(false));

    const closeAboutUs = () => {
        setShowAboutUs(false);
    };

    return (
        <div ref={aboutRef} className={`${styles.container} ${showAboutUs ? styles.active : ''}`}>
            <i className={styles.close} onClick={closeAboutUs}>
                <img src={close_icon} alt="close"/>
            </i>
            <div className={styles.title}> About Charm</div>
            <div className={styles.divider} />
            <div>
                <div className={styles.instruction}>Hi there! We're Dom and Hammad, two recent college grads who moved into the city a couple years ago. As newcomers, we found it challenging to uncover what all the nearby local stores had to offer. We figured if more people could search through local shop inventory, it would be easier to support small businesses!</div>
            </div>

        </div>
    );
};

export default AboutUsOverlay;