import React from 'react';
import styles from './n_Pagination.module.css'; // Replace with your actual CSS module file
import leftarrow_icon from '../7_icons/leftarrow_icon.svg'; // Assuming path is the same
import rightarrow_icon from '../7_icons/rightarrow_icon.svg'; // Assuming path is the same

function Pagination({ currentPage, totalPages, handlePageChange }) {

    
  return (
    <div className={styles.pagination}>
         {/* Updated line for showing current page and total pages */}
      <div className={styles.subheader}>{`${currentPage+1} of ${totalPages}`}</div>
      <button
        onClick={() => handlePageChange(Math.max(0, currentPage - 1))}
        disabled={currentPage === 0}
        className={styles.pagebutton}
      >
        <img src={leftarrow_icon} alt="Previous" />
      </button>

     

      <button
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages - 1}
        className={styles.pagebutton}
      >
        <img src={rightarrow_icon} alt="Next" />
      </button>
    </div>
  );
}

export default Pagination;
