import algoliasearch from 'algoliasearch/lite';

const algoliaClient = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID, process.env.REACT_APP_ALGOLIA_API_KEY_MAIN);
const index = algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_INDEX_NAME_MAIN);

let counter = 0;

export async function fetchFacetsWithinNeighborhood(facet, facetFilters = []) {
    counter ++;
    console.log("fetchFacetsWithinNeighborhood " + counter)
    try {
        
        const searchParams = {
            facets: [facet],
            ...(facetFilters.length > 0 && { facetFilters: facetFilters })
        };
        const response = await index.search('', searchParams);
        if (response.facets && response.facets[facet]) {
            return Object.keys(response.facets[facet]);
        }
        return []; 
    } catch (error) {
        console.error('Error fetching unique facet values:', error);
        throw error; 
    }
}
