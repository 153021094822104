export function addToCart(newItem, setCartItems, quantity) {
    setCartItems((prevItems) => {
      // Check if the item already exists in the cart
      const existingItemIndex = prevItems.findIndex(item => item.id === newItem.id);
      let updatedItems = [];
      if (existingItemIndex >= 0) {
        // If the item exists, increase its quantity
        updatedItems = prevItems.map((item, index) => {
          if (index === existingItemIndex) {
            return { ...item, quantity: item.quantity + 1 };
          }
          return item;
        });
      } else {
        // If the item does not exist, add it with a quantity of 1
        updatedItems = [...prevItems, { ...newItem, quantity: quantity }];
      }
      localStorage.setItem('cartItems', JSON.stringify(updatedItems));
      return updatedItems;
    });
  };
  