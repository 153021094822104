import algoliasearch from 'algoliasearch/lite';

const algoliaClient = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID, process.env.REACT_APP_ALGOLIA_API_KEY_MAIN);
const index = algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_INDEX_NAME_MAIN);

let counter = 0;

export async function fetchFeaturedItems(facet, facetName, availableStores, featureableStores, resultsPerPage) {
  counter ++;
  console.log("fetchFeaturedItems " + counter)
  try {
    // Create a filter for the specific store name
    const mainFilter = `${facet}:${facetName}`;
    const availableStoresFilter = availableStores.map(store => `store:${store}`);
    const featureableStoresFilter = featureableStores.map(store => `store:${store}`);
    const facetFilters = [
      [mainFilter], // Ensures specific facet condition
      availableStoresFilter, // All available stores - combined with OR
      featureableStoresFilter, // All featureable stores - combined with OR
    ];

    const response = await index.search('', {
      facetFilters: facetFilters, // Pass the adjusted filter structure
      hitsPerPage: resultsPerPage,
    });

    return { items: response.hits, total: response.nbHits };
  } catch (error) {
    console.error(`Error fetching items within "${facetName}":`, error);
    console.log({ items: [] });
    return { items: [], total: 0 };
    
  }
}

