// utils/analytics.js
//Ensures we only track stuff on production build
export function trackEvent(action, params) {
    if (process.env.NODE_ENV === 'production') {
      if (window.gtag) {
        window.gtag('event', action, params);
      }
    }
  }
  
  export function trackPageView(path) {
    if (process.env.NODE_ENV === 'production') {
      if (window.gtag) {
        window.gtag('config', 'G-W17DEQW0DE', {
          'page_path': path,
        });
      }
    }
  }
