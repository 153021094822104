import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';        
import Explore from './1_pages/a_explore/1_Explore/a_Explore.js';
import ItemDetails from './1_pages/b_item/1_Item/a_ItemDetails.js';
import './App.css';
import innerrichmond from './6_images/a_neighborhoods/innerrichmond.jpg';
import outerrichmond from './6_images/a_neighborhoods/outerrichmond.jpg';
import japantown from './6_images/a_neighborhoods/japantown.jpg';
import sixthavenueaquarium from './6_images/b_stores/1_image/sixthavenueaquarium.jpg';
import ddcycles from './6_images/b_stores/1_image/ddcycles.jpg';
import electricbikecenter from './6_images/b_stores/1_image/electricbikecenter.jpg';
import engrafft from './6_images/b_stores/1_image/engrafft.jpg';
import eyeandhandsociety from './6_images/b_stores/1_image/eyeandhandsociety.jpg';
import felasdiscount from './6_images/b_stores/1_image/felasdiscount.jpg';
import finerflora from './6_images/b_stores/1_image/finerflora.jpg';
import foggynotion from './6_images/b_stores/1_image/foggynotion.jpg';
import gablesstationary from './6_images/b_stores/1_image/gablesstationary.jpg';
import gaslightandshadows from './6_images/b_stores/1_image/gaslightandshadows.jpg';
import gearyshoerepair from './6_images/b_stores/1_image/gearyshoerepair.jpg';
import goldstardiscount from './6_images/b_stores/1_image/goldstardiscount.jpg';
import janeconsignment from './6_images/b_stores/1_image/janeconsignment.jpg';
import jvdglobal from './6_images/b_stores/1_image/jvdglobal.jpg';
import lovestreetvintage from './6_images/b_stores/1_image/lovestreetvintage.jpg';
import ornot from './6_images/b_stores/1_image/ornot.jpg';
import paloma from './6_images/b_stores/1_image/paloma.jpg';
import sparkysballoons from './6_images/b_stores/1_image/sparkysballoons.jpg';
import thefuturepast from './6_images/b_stores/1_image/thefuturepast.jpg';
import uncommonthrifts from './6_images/b_stores/1_image/uncommonthrifts.jpg';
import varietyshop from './6_images/b_stores/1_image/varietyshop.jpg';
import wishingwellworkshop from './6_images/b_stores/1_image/wishingwellworkshop.jpg';
import woodgoodsandhotsauce from './6_images/b_stores/1_image/woodgoodsandhotsauce.jpg';
import arguellosalon from './6_images/b_stores/1_image/arguellosalon.jpg';
import thedrawingroom from './6_images/b_stores/1_image/thedrawingroom.jpg';
import aladdinrugs from './6_images/b_stores/1_image/aladdinrugs.jpg';
import clementnursery from './6_images/b_stores/1_image/clementnursery.jpg';
import chato from './6_images/b_stores/1_image/chato.jpg';
import aquaforestaquarium from './6_images/b_stores/1_image/aquaforestaquarium.jpg';
import kinokuniya from './6_images/b_stores/1_image/kinokuniya.jpg';
import maido from './6_images/b_stores/1_image/maido.jpg';
import food24market from './6_images/b_stores/1_image/food24market.jpg';
import olive from './6_images/b_stores/1_image/olive.jpg';
import trendinghouseware from './6_images/b_stores/1_image/trendinghouseware.jpg';

import sixthavenueaquariumblock from './6_images/b_stores/3_block/sixthavenueaquariumblock.jpg';
import ddcyclesblock from './6_images/b_stores/3_block/ddcyclesblock.jpg';
import electricbikecenterblock from './6_images/b_stores/3_block/electricbikecenterblock.jpg';
import engrafftblock from './6_images/b_stores/3_block/engrafftblock.jpg';
import eyeandhandsocietyblock from './6_images/b_stores/3_block/eyeandhandsocietyblock.jpg';
import felasdiscountblock from './6_images/b_stores/3_block/felasdiscountblock.jpg';
import finerflorablock from './6_images/b_stores/3_block/finerflorablock.jpg';
import foggynotionblock from './6_images/b_stores/3_block/foggynotionblock.jpg';
import gablesstationaryblock from './6_images/b_stores/3_block/gablesstationaryblock.jpg';
import gaslightandshadowsblock from './6_images/b_stores/3_block/gaslightandshadowsblock.jpg';
import gearyshoerepairblock from './6_images/b_stores/3_block/gearyshoerepairblock.jpg';
import goldstardiscountblock from './6_images/b_stores/3_block/goldstardiscountblock.jpg';
import janeconsignmentblock from './6_images/b_stores/3_block/janeconsignmentblock.jpg';
import jvdglobalblock from './6_images/b_stores/3_block/jvdglobalblock.jpg';
import lovestreetvintageblock from './6_images/b_stores/3_block/lovestreetvintageblock.jpg';
import ornotblock from './6_images/b_stores/3_block/ornotblock.jpg';
import palomablock from './6_images/b_stores/3_block/palomablock.jpg';
import sparkysballoonsblock from './6_images/b_stores/3_block/sparkysballoonsblock.jpg';
import thefuturepastblock from './6_images/b_stores/3_block/thefuturepastblock.jpg';
import uncommonthriftsblock from './6_images/b_stores/3_block/uncommonthriftsblock.jpg';
import varietyshopblock from './6_images/b_stores/3_block/varietyshopblock.jpg';
import wishingwellworkshopblock from './6_images/b_stores/3_block/wishingwellworkshopblock.jpg';
import woodgoodsandhotsauceblock from './6_images/b_stores/3_block/woodgoodsandhotsauceblock.jpg';
import arguellosalonblock from './6_images/b_stores/3_block/arguellosalonblock.jpg';
import thedrawingroomblock from './6_images/b_stores/3_block/thedrawingroomblock.jpg';
import aladdinrugsblock from './6_images/b_stores/3_block/aladdinrugsblock.jpg';
import clementnurseryblock from './6_images/b_stores/3_block/clementnurseryblock.jpg';
import chatoblock from './6_images/b_stores/3_block/chatoblock.jpg';
import aquaforestaquariumblock from './6_images/b_stores/3_block/aquaforestaquariumblock.jpg';
import kinokuniyablock from './6_images/b_stores/3_block/kinokuniyablock.jpg';
import maidoblock from './6_images/b_stores/3_block/maidoblock.jpg';
import food24marketblock from './6_images/b_stores/3_block/food24marketblock.jpg';
import oliveblock from './6_images/b_stores/3_block/oliveblock.jpg';
import trendinghousewareblock from './6_images/b_stores/3_block/trendinghousewareblock.jpg';

import ddcycleslogo from './6_images/b_stores/2_logo/ddcycleslogo.jpg';
import engrafftlogo from './6_images/b_stores/2_logo/engrafftlogo.jpg';
import foggynotionlogo from './6_images/b_stores/2_logo/foggynotionlogo.jpg';
import janeconsignmentlogo from './6_images/b_stores/2_logo/janeconsignmentlogo.jpg';
import lovestreetvintagelogo from './6_images/b_stores/2_logo/lovestreetvintagelogo.jpg';
import ornotlogo from './6_images/b_stores/2_logo/ornotlogo.jpg';
import palomalogo from './6_images/b_stores/2_logo/palomalogo.jpg';
import sparkysballoonslogo from './6_images/b_stores/2_logo/sparkysballoonslogo.jpg';
import thefuturepastlogo from './6_images/b_stores/2_logo/thefuturepastlogo.jpg';
import woodgoodsandhotsaucelogo from './6_images/b_stores/2_logo/woodgoodsandhotsaucelogo.jpg';
import arguellosalonlogo from './6_images/b_stores/2_logo/arguellosalonlogo.jpg';
import thedrawingroomlogo from './6_images/b_stores/2_logo/thedrawingroomlogo.jpg';
import aladdinrugslogo from './6_images/b_stores/2_logo/aladdinrugslogo.jpg';
import chatologo from './6_images/b_stores/2_logo/chatologo.jpg';
import aquaforestaquariumlogo from './6_images/b_stores/2_logo/aquaforestaquariumlogo.jpg';
import kinokuniyalogo from './6_images/b_stores/2_logo/kinokuniyalogo.jpg';
import olivelogo from './6_images/b_stores/2_logo/olivelogo.jpg';

import { GlobalProvider } from './3_context/a_GlobalContext.js';
import { useEffect } from 'react';
import { trackPageView } from './4_utils/i_GoogleAnalytics.js';


// FOR NEIGHBORHOOD HIGHLIGHT WIDGETS
export const neighborhoodTaglines = {
"The Richmond" : "The Richmond District has a rich history of small businesses that reflect the cultural diversity and evolving community dynamics of the area. Originally developed in the late 19th and early 20th centuries, the neighborhood saw an influx of immigrants, particularly from Russia, Ireland, and later, Asia, which shaped the local business landscape. Small businesses in the Richmond District started primarily as family-owned shops, eateries, and service-based establishments catering to the needs of the local residents. Explore the neighborhoods's growing, diverse storefront offerings!",
"Outer Richmond": "Small businesses in Outer Richmond, San Francisco, have thrived since the late 19th century, catering to the local community's needs with mom-and-pop stores like grocery shops and bakeries. Post-World War II, the neighborhood experienced growth, fueled by immigrants, especially from Asia, who brought diversity and entrepreneurship. This led to the establishment of ethnic eateries and specialty shops. Over time, Outer Richmond has evolved while retaining its unique character and sense of community, with small businesses adapting to changing consumer preferences. Check out the vibrant hub of small-scale enterprises which offer a diverse array of goods and services.",
"Japantown": "Japantown has a storied history dating back to the late 19th century when Japanese immigrants settled in the area. Originally known as 'Nihonmachi' it grew into a thriving cultural and commercial center for the Japanese American community on the West Coast. Despite post-WW2 hardships, Japantown persevered and experienced a resurgence over the past few decades, becoming a hub of Japanese culture, cuisine, and commerce. Today, it remains a vital part of the city's cultural fabric, with a mix of traditional and contemporary businesses, including restaurants, and unique storefront offerings.",
};

export const neighborhoodImages = {
"The Richmond": innerrichmond,
"Outer Richmond": outerrichmond,
"Japantown": japantown,
};

// FOR STORE HIGHLIGHT WIDGETS
export const storeTaglines = {
  "6th Avenue Aquarium": "Located on Clement Street in the heart of the Richmond District, is a vibrant and eclectic fish store known for its diverse selection of freshwater and saltwater fish, as well as plants, corals, and aquarium supplies. This neighborhood gem attracts both seasoned aquarists and new hobbyists with its array of exotic fish, friendly and knowledgeable staff, and serene shopping atmosphere. Whether you're looking to start a new aquarium or seeking rare aquatic life, 6th Avenue Aquarium offers a unique and immersive experience for all aquatic enthusiasts.",
  "Aladdin Rugs":"Owner Albert, originally from a small town in Morocco, came to San Francisco over 30 years ago to build a better life. He began sourcing and selling rugs at flea markets around the Bay Area. As his business grew, he established a small store in the Richmond District of San Francisco. He later found a way to source floor model mattresses from large retailers and offer his customers deep discounts on high quality mattresses; expanding his business to serve customers with great mattresses at the most affordable prices. Being a native Moroccan, Albert speaks French and Arabic, along with English. Albert loves having company at his store. Come in and brush up on your language skills!",
  "Clement Nursery": "Clement Nursery is the oldest establishment of its kind in San Francisco and has been around for more than 80 years. However, the site dates back even earlier to one of the Richmond District’s first dairy farms, which was referred to as “Old Park Farm” or “Richmond Dairy.” Beginning in 1880, Theophilis Paton tended to 33 cows at the intersection of 21st Avenue and Clement Street. At first, his farm was the sole structure in the neighborhood for more than a mile, according to Western Neighborhoods Project co-founder Woody LaBounty. The Point Lobos Toll Road — now Geary Boulevard — was the only street connecting Paton’s dairy to the rest of the city, allowing him to easily deliver the milk downtown.",
  "Arguello Salon":"Arguello Salon, located on bustling Geary Street in the Richmond District, is a contemporary hair salon that offers a wide range of styling and beauty services. Known for its professional and welcoming atmosphere, the salon caters to a diverse clientele, providing expert haircuts, coloring, styling, and treatment services. Owner Flora is an experienced stylist dedicated to personalized service and attention to detail, Arguello Salon ensures that every client leaves feeling refreshed and confident. Whether you're seeking a dramatic transformation or a simple trim, Arguello Salon is a trusted destination for your beauty needs.",
  "DD Cycles": "DD Cycles, nestled on Geary Street in the vibrant Richmond District, is a premier bicycle shop known for its extensive selection of bikes, parts, and accessories. Catering to cycling enthusiasts of all levels, from beginners to seasoned professionals, the shop offers a wide range of services including bike sales, repairs, and maintenance. The knowledgeable and passionate staff at DD Cycles are committed to helping customers find the perfect bike and gear, ensuring a safe and enjoyable cycling experience",
  "Electric Bike Center": "Electric Bike Center, situated on Geary Street in the Richmond District, is a cutting-edge bike shop specializing in electric bicycles. This shop is a haven for eco-conscious commuters and cycling enthusiasts looking to embrace the electric bike revolution. Offering a wide selection of e-bikes, from commuter models to mountain and folding bikes, the Electric Bike Center caters to all needs and preferences. The experienced team provides expert advice, test rides, and comprehensive after-sales service, ensuring customers find the perfect e-bike for their lifestyle. With a commitment to quality and customer satisfaction, Electric Bike Center is the go-to destination for those looking to upgrade their ride and contribute to a greener future.",
  "ENGRAFFT": "Started in 2014, ENGRAFFT is the artist created, owned and operated, graffiti rooted, “Art Infused” clothing and goods line from the Monterey Peninsula and San Francisco, California. Started by SF born, SF/ Monterey raised artist, fashion and graphic designer and screen-printer Derek Hanneman in 2014 in Pacific Grove, CA, ENGRAFFT combines several art forms including illustration, graphic design and screen-printing, roots in graffiti, with various ‘living’ graphic art styles and high quality, super soft clothing and goods.",
  "Eye & Hand Society": "Eye and Hand Society, located in the tranquil Outer Richmond area of San Francisco, is a unique boutique that seamlessly blends contemporary clothing with captivating art. This shop stands out for its curated collection of fashion-forward apparel and locally sourced artwork, providing a platform for both emerging and established artists and designers. The store's aesthetic is a testament to creativity and craftsmanship, offering a diverse range of items from handcrafted jewelry to bespoke garments and intriguing art pieces. Eye and Hand Society is more than just a shop; it's a cultural hub where fashion enthusiasts and art lovers alike can explore and discover inspiring new works in a welcoming and imaginative environment.",
  "Fela's Discount": "Fela's Discount, nestled in the heart of the Richmond district, is a long-standing Iranian shop with a rich history and cultural heritage. This unique store specializes in an array of beautiful tapestries and comfortable mattresses, offering a glimpse into the traditional craftsmanship and artistic expressions of Iran. Despite its unassuming exterior, Fela's Discount is a treasure trove of intricately designed textiles and quality bedding solutions, catering to those in search of authentic, cultural items and home comforts. The shop stands as a testament to the enduring spirit and cultural pride of the local Iranian community, providing a warm and inviting shopping experience for all who enter.",
  "Finer Flora": "Finer Flora has been hand-delivering premium floral arrangements and gift baskets to help strengthen relationships, give love and support, and celebrate life’s special moments since 2014. From birthdays to anniversaries to commemorating a loved one, and every little “just because” moment in between. Their stunning arrangements will allow nature’s beauty to inspire your special moment. And because we are a local florist dedicated to your satisfaction, you can always expect your needs to be treated with all the attention to detail they deserve.",
  "Foggy Notion": "Established in 2011, Foggy Notion has become a favorite neighborhood gift shop for locals in-the-know, but also a Bay Area shopping destination due to its clever and thoughtful curation by owner Alissa Anderson. Regulars come in to restock on their favorite non-toxic candles and incense or try a captivating new seasonal scent. The apothecary section is full of organic bar soap, bath salts, and face oils with high quality ingredients and gorgeous packaging. Pantry shelves have tasty treats both sweet and savory. Home goods include plastic-free cleaning brushes, zero-waste reusables, and vintage ceramics & glassware. Cards, pins, jewelry, stained glass, plants and vintage clothes round out their offerings.",
  "Gable's Stationary": "Gable's Stationary, located on Geary Street in the Richmond district, is a traditional office supply shop with a nostalgic charm. Specializing in a wide range of stationary items, from classic writing instruments to unique paper products, Gable's offers a step back in time to when selecting the perfect pen or notebook was a cherished ritual. This shop has served the local community for years, providing essential office and school supplies along with personalized customer service. Despite its modest appearance, Gable's Stationary remains a beloved staple in the neighborhood, catering to those who appreciate the tangible and timeless nature of handwritten correspondence and crafted stationery.",
  "Gaslight & Shadows": "Gaslight & Shadows Antiques, antique store in San Francisco founded by Phyllis Nabhan in 1976. The goal was to create a fun store that was friendly, affordable, and packed with unique interesting items of many types. The store has been a neighborhood favorite for many years and now thanks to the Internet, attracts people from all over the country and World. Specializing vintage Bakelite jewelry and items, and Limoges Boxes, the store has collections not found elsewhere.",
  "Geary Shoe Repair": "Geary Shoe Repair, situated on the bustling Geary Street in the Richmond district, is a testament to the timeless craft of shoe restoration and repair. This shop has been a cornerstone in the community for years, known for its meticulous attention to detail and traditional repair techniques. The experienced cobblers at Geary Shoe Repair breathe new life into worn and cherished footwear, from vintage boots to modern heels, ensuring each pair is returned to its former glory. Despite its unassuming facade, the shop is a treasure trove of craftsmanship, where every shoe tells a story and every repair is done with precision and care. It stands as a beloved local institution, preserving the art of shoe repair for future generations.",
  "Goldstar Discount": "Goldstar Discount, located on Clement Street in the Richmond district, is a well-loved discount shop known for its eclectic array of products at affordable prices. Among its diverse offerings, the store features a selection of unique Chinese trinkets and cultural items, subtly reflecting the diverse heritage of the neighborhood. While providing everyday essentials, Goldstar also offers customers a variety of global artifacts, including a modest but charming collection of items inspired by different cultures. This includes a few special pieces that hint at the rich tapestry of the local community without overwhelming the store's universal appeal. With its welcoming environment and wide-ranging inventory, Goldstar Discount remains a favorite local destination for those in search of value and a hint of cultural discovery.",
  "JANE Consignment": "JANE Consignment, located in San Francisco's Outer Richmond district since 2001, offers a 'Deliciously Eclectic' collection of women's designer fashions, unique jewelry, and distinctive gifts. The store features a diverse range of items, from high-end Chanel skirts and Fendi purses to vintage sweaters and retro boots, catering to various styles and preferences. JANE also highlights local designer jewelry, fine gold and sterling pieces, and gifts like cashmere scarves from Nepal and colorful umbrellas, making it a treasure trove for those seeking unique finds and creative flair.",
  "JVD Global": "JVD Global, situated on Clement Street in the Richmond district, is a discount electronics store known for its wide selection of gadgets and tech accessories at competitive prices. This shop caters to a variety of tech needs, offering everything from the latest in audio equipment and smartphones to essential computer accessories and unique electronic finds. Despite its modest exterior, JVD Global is a haven for technology enthusiasts and bargain hunters alike, providing a diverse inventory that appeals to both the tech-savvy and those just looking for a good deal. The store's commitment to affordable technology, coupled with its knowledgeable staff, makes JVD Global a valued part of the local community, ensuring customers leave satisfied with their discoveries.",
  "Love Street Vintage": "Love Street Vintage, once a staple at the iconic corner of Haight and Ashbury, has found a new home on Balboa Street in San Francisco. The owner, Graciela Ronconi, moved her store after a decade in the Haight, seeking respite from the area's challenges, including extensive street construction and the impacts of COVID-19. Despite these hurdles, Ronconi's passion for vintage clothing and her dedication to sustainable fashion have remained unwavering. Her new location on Balboa Street has become an unexpected hit, resonating well with the Richmond District's community. The store, which started as an overflow storage space, now offers a vibrant array of brightly colored vintage frocks, beaded accessories, and handcrafted items, maintaining a special emphasis on Native American pieces.",
  "Ornot": "Ornot was founded in 2013 by Matt, who was inspired to challenge the norm of massive logos and team kits prevalent in cycling culture. The brand's ethos is rooted in the dynamic weather conditions of San Francisco, leading to an obsession with creating clothing that excels in breathability, wicking, and packability. Ornot's gear is thoughtfully constructed using sustainable materials such as recycled fibers, merino wool, and deadstock fabric, underscoring a commitment to ethical production and quality construction. A significant portion of their clothing is proudly produced in the USA, reflecting their stance against fast fashion while promoting durability and local manufacturing. Ornot aims to transform the cycling apparel landscape by offering well-designed, minimally branded gear that provides an alternative to being a rolling billboard.",
  "Paloma": "Located in San Francisco's Outer Richmond, Paloma offers unique leather goods crafted on-site under the SF Breakers label by Laureano Faedi, who also designs the historically inspired Gangs of San Francisco apparel. Specializing in hand-sewn products using top-quality materials, Paloma emphasizes American-made sources and guarantees products for life. The shop invites custom orders, ensuring personalized attention for the perfect bag or accessory. Besides leather items, Paloma carries an array of beloved home goods, gifts, textiles, and jewelry. Visitors are encouraged to stop by with their furry friends to explore this local gem just four blocks from Ocean Beach.",
  "Sparky's Balloons": "Sparky's Balloons, located on Clement Street in San Francisco, is the go-to destination for balloon enthusiasts and party planners alike. Owned and operated by Sal, this vibrant shop specializes in a wide array of balloons, from classic latex to intricate foil designs suitable for every occasion. Sal and his team are known for their creativity and attention to detail, ensuring each balloon arrangement adds a special touch to any event. Sparky's Balloons also offers a variety of party supplies, making it a one-stop shop for celebratory needs. With a commitment to customer satisfaction and a passion for bringing joy to the community, Sal has made Sparky's Balloons a beloved part of the local landscape.",
  "The Future Past": "The Future Past was born out of a love of vintage and second hand clothing. We collect special pieces based on fabric, hand feel, construction, and it’s potential to be revived. We love the feeling of perfectly worn-in cloth that feels like a second skin. We prefer buying second hand or vintage over something new. We use natural (plant and botanical) dyes on hand selected garments to give them a colorful second life. We mend, patch, embroider, and restore pieces to extend their life and accentuate their beauty. We offer alterations on most pieces because we all deserve to feel good and look amazing in our clothes. Besides, if it doesn’t fit-you won’t wear it. We want you to wear and LOVE each one-of-a-kind piece that you purchase from us. If you love and wear it so much that it needs repair then bring it back for mending.",
  "Uncommon Thrifts": "Uncommon Thrifts, founded by Erin in 2018, stands out as a unique vintage clothing resale and lifestyle brand in the heart of the community. Erin has meticulously planned and coordinated every aspect of the store, from curation and styling to organizing events, creating a distinctive shopping experience. The brand specializes in offering a carefully selected range of vintage attire, emphasizing the individuality and style of each piece. Additionally, on weekends and Fridays, Uncommon Thrifts transforms, extending its eclectic mix outside of Wood Goods & Hot Sauve with clothing racks, available for the discerning shopper. This approach not only showcases Erin's dedication to the brand but also fosters a vibrant, market-like atmosphere where customers can discover uncommon finds and lifestyle items.",
  "Variety Shop": "Variety Shop, located on Clement Street in the Richmond district, is a treasure trove of discount goods catering to a wide range of needs and interests. Known for its vast selection, the store offers everything from household essentials and kitchen gadgets to toys, beauty products, and seasonal decorations. As a one-stop shop for budget-friendly items, Variety Shop prides itself on providing value and variety to the local community. Whether you're outfitting your home, searching for the perfect gift, or just exploring, you're sure to find something unique and affordable.",
  "Wishing Well Workshop": "The Wishing Well Workshop, located in the heart of the community, is a unique blend of art supply store and custom print shop. Specializing in screen printing, letterpress, and direct-to-garment services, they offer personalized assistance to help bring your T-shirt designs and other custom print ideas to life. Beyond printing services, The Wishing Well boasts an impressive collection of pens and pencils from around the globe, catering to artists and writing enthusiasts alike. Whether you're looking to create custom apparel or simply explore a diverse range of high-quality art supplies, The Wishing Well Workshop provides a creative haven for all your artistic needs.",
  "Wood Goods & Hot Sauce": "Alyssa and Tim Herod are the dynamic duo behind Wood Goods and Hot Sauce, a unique shop and community hub in San Francisco's Richmond District. Their venture began with the simple idea of selling Tim's homemade hot sauces and showcasing wood sculptures from Alyssa's brother. Since opening, the store has blossomed into a diverse retail space offering products from small businesses and makers, including textiles, self-care items, kitchenware, ceramics, and a wide variety of hot sauces. Additionally, the shop features a gallery wall hosting six art openings a year to highlight local and Bay Area artists. Stemming from their backgrounds in set design and styling, and their joint venture in home staging, Alyssa and Tim have created a welcoming space that reflects their passions for art, home goods, and community. As new parents, they've achieved their dream of becoming a 'Mom and Pop' shop, extending an invitation to all to explore their curated collection and share in their love for creative and inviting home spaces.",
  "The Drawing Room": "The Drawing Room SF, originally a small art studio and gallery, has grown into a vibrant community space empowering local artists and students. Through exhibitions, workshops, and live events, it offers artists a platform to showcase their work and connects them with the broader community. Additionally, The Drawing Room partners with the San Francisco Unified School District, mentoring high school students in the arts, thus nurturing the next generation of creatives. This commitment to art and education enriches the cultural landscape and makes art an accessible and integral part of everyday life.",
  "Aqua Forest Aquarium": "Aqua Forest Aquarium, established in San Francisco in July 2004 by brothers George and Steven Lo, specializes in the Nature Aquarium concept. This unique retail and online store offers an extensive range of aquarium products, including the premium ADA line from Japan. Dedicated to quality and innovation, Aqua Forest provides everything needed for creating a Nature Aquarium, catering to enthusiasts both locally and nationwide. The store is committed to promoting the Nature Aquarium style, ensuring a comprehensive shopping experience for all aquarium lovers.",
  "ChaTo": "ChaTo is a family-run Japanese tea shop that offers a personal journey through premium Japanese loose leaf teas and matcha powders, sourced from small producers that the family personally loves. Founded with a passion passed down through generations, and inspired by childhood memories of drinking sencha, ChaTo fills a niche in Japantown for authentic Japanese tea and tea ware, leveraging the family's extensive experience in Japanese pottery. Since 2016, ChaTo has been catering to both beginners and experts in tea brewing, providing essentials and a wide selection of teas including Benifuuki, Genmaicha with Matcha, Gyokuro, Houjicha, and Sencha. Their curated tea selection comes from renowned regions like Shizuoka, Kagoshima, Uji, and Fukuoka, ensuring quality and authenticity. ChaTo is dedicated to making the rich tradition of Japanese tea accessible and enjoyable for the community.",
  "Kinokuniya": "Kinokuniya USA, a branch of the renowned Japanese bookstore chain, provides a diverse selection of books, magazines, and stationery imported from Japan. The store prides itself on an extensive collection that includes manga, graphic novels, art and design books, cookbooks, travel guides, children's literature, and more, available in both English and Japanese. Additionally, the Seattle store features a selection of Chinese books, catering to a wider audience. Kinokuniya USA aims to bring the rich literary and artistic culture of Japan to American shores, offering a unique shopping experience for enthusiasts of all ages.",
  "Mai Do": "Mai Do is a specialty stationery store under the Kinokuniya Group, known for its unique and high-quality Japanese products. The store offers an extensive range of items including specialty papers, pens, fine stationery, calligraphy supplies, gift items, organizers, and collectibles. Catering to both enthusiasts and professionals, Mai Do provides a curated selection of goods that celebrate the artistry and craftsmanship of Japanese stationery design.",
  "Food 24 Market": "This locally owned corner store in the Richmond district is a cherished part of the community, offering a wide range of products from everyday necessities to unique finds. Known for its friendly service and welcoming atmosphere, the store caters to the needs of the neighborhood, providing fresh produce, pantry staples, snacks, and beverages. As a hub for locals, it not only serves as a convenient stop for quick shopping but also as a gathering place where residents connect and catch up. The store's commitment to the community and its dedication to personal customer service make it a beloved landmark in the Richmond district.",
  "Trending Houseware": "Trending Houseware, located on Clement Street in the Richmond district, is a popular destination for affordable home goods. This store offers a wide variety of household items, from kitchen essentials and decorative pieces to organizational solutions and bathroom accessories. Known for its budget-friendly prices and diverse inventory, Trending Houseware caters to a range of styles and needs, making it easy for customers to find exactly what they need to enhance their living spaces. The welcoming atmosphere and helpful staff ensure a pleasant shopping experience, solidifying Trending Houseware's status as a go-to spot for locals seeking quality home goods without breaking the bank.",
  "Olive": "Olive Specialty Store stands out as a unique destination for Mediterranean and Middle Eastern culinary delights, located in the vibrant heart of the community. This specialty grocery store offers an unparalleled selection of authentic ingredients and products that are hard to find elsewhere. From exotic spices and flavorful oils to traditional sweets and artisanal cheeses, Olive provides food enthusiasts and cultural explorers with the opportunity to discover and indulge in rare and authentic tastes from these rich culinary regions. Whether you're a seasoned chef or a curious foodie, Olive Specialty Store is your gateway to exploring the genuine flavors of the Mediterranean and Middle East without leaving town.",
};


export const storeImages = {
  "6th Avenue Aquarium": sixthavenueaquarium,
  "Arguello Salon": arguellosalon,
  "DD Cycles": ddcycles,
  "Electric Bike Center": electricbikecenter,
  "ENGRAFFT": engrafft,
  "Eye & Hand Society": eyeandhandsociety,
  "Fela's Discount": felasdiscount,
  "Finer Flora": finerflora,
  "Foggy Notion": foggynotion,
  "Gable's Stationary": gablesstationary,
  "Gaslight & Shadows": gaslightandshadows,
  "Geary Shoe Repair": gearyshoerepair,
  "Goldstar Discount": goldstardiscount,
  "JANE Consignment": janeconsignment,
  "JVD Global": jvdglobal,
  "Love Street Vintage": lovestreetvintage,
  "Ornot": ornot,
  "Paloma": paloma,
  "Sparky's Balloons": sparkysballoons,
  "The Future Past": thefuturepast,
  "Uncommon Thrifts": uncommonthrifts,
  "Variety Shop": varietyshop,
  "Wishing Well Workshop": wishingwellworkshop,
  "Wood Goods & Hot Sauce": woodgoodsandhotsauce,
  "The Drawing Room": thedrawingroom,
  "Aladdin Rugs": aladdinrugs,
  "Clement Nursery": clementnursery,
  "ChaTo": chato,
  "Mai Do": maido,
  "Aqua Forest Aquarium": aquaforestaquarium,
  "Kinokuniya": kinokuniya,
  "Food 24 Market": food24market,
  "Trending Houseware": trendinghouseware,
  "Olive": olive
};

export const storeBlockImages = {
  "6th Avenue Aquarium": sixthavenueaquariumblock,
  "Arguello Salon": arguellosalonblock,
  "DD Cycles": ddcyclesblock,
  "Electric Bike Center": electricbikecenterblock,
  "ENGRAFFT": engrafftblock,
  "Eye & Hand Society": eyeandhandsocietyblock,
  "Fela's Discount": felasdiscountblock,
  "Finer Flora": finerflorablock,
  "Foggy Notion": foggynotionblock,
  "Gable's Stationary": gablesstationaryblock,
  "Gaslight & Shadows": gaslightandshadowsblock,
  "Geary Shoe Repair": gearyshoerepairblock,
  "Goldstar Discount": goldstardiscountblock,
  "JANE Consignment": janeconsignmentblock,
  "JVD Global": jvdglobalblock,
  "Love Street Vintage": lovestreetvintageblock,
  "Ornot": ornotblock,
  "Paloma": palomablock,
  "Sparky's Balloons": sparkysballoonsblock,
  "The Future Past": thefuturepastblock,
  "Uncommon Thrifts": uncommonthriftsblock,
  "Variety Shop": varietyshopblock,
  "Wishing Well Workshop": wishingwellworkshopblock,
  "Wood Goods & Hot Sauce": woodgoodsandhotsauceblock,
  "The Drawing Room": thedrawingroomblock,
  "Aladdin Rugs": aladdinrugsblock,
  "Clement Nursery": clementnurseryblock,
  "ChaTo": chatoblock,
  "Mai Do": maidoblock,
  "Aqua Forest Aquarium": aquaforestaquariumblock,
  "Kinokuniya": kinokuniyablock,
  "Food 24 Market": food24marketblock,
  "Trending Houseware": trendinghousewareblock,
  "Olive": oliveblock
};

export const storeLogos = {
  "Arguello Salon": arguellosalonlogo,
  "DD Cycles": ddcycleslogo,
  "ENGRAFFT": engrafftlogo,
  "Foggy Notion": foggynotionlogo,
  "JANE Consignment": janeconsignmentlogo,
  "Love Street Vintage": lovestreetvintagelogo,
  "Ornot": ornotlogo,
  "Paloma": palomalogo,
  "Sparky's Balloons": sparkysballoonslogo,
  "The Future Past": thefuturepastlogo,
  "Wood Goods & Hot Sauce": woodgoodsandhotsaucelogo,
  "The Drawing Room": thedrawingroomlogo,
  "Aladdin Rugs": aladdinrugslogo,
  "ChaTo": chatologo,
  "Aqua Forest Aquarium": aquaforestaquariumlogo,
  "Kinokuniya": kinokuniyalogo,
  "Olive": olivelogo,
};

export const storeHours = {
"ENGRAFFT": "Thursday: 12-9 PM, Friday: 12-7 PM, Saturday: 12-5 PM, Sunday: 12-4 PM, Monday: Closed, Tuesday: 12-7 PM, Wednesday: 12-7 PM",
"Clement Nursery": "Thursday: 10 AM-5 PM, Friday: 10 AM-5 PM, Saturday: 10 AM-5 PM, Sunday: 10 AM-5 PM, Monday: 10 AM-5 PM, Tuesday: 10 AM-5 PM, Wednesday: 10 AM-5 PM",
"Aladdin Rugs":"Thursday: 10 AM-5 PM, Friday: 10 AM-5 PM, Saturday: 10 AM-5 PM, Sunday: 10 AM-5 PM, Monday: 10 AM-5 PM, Tuesday: 10 AM-5 PM, Wednesday: 10 AM-5 PM",
"Foggy Notion": "Thursday: 10 AM-7 PM, Friday: 10 AM-7 PM, Saturday: 10 AM-7 PM, Sunday: 9 AM-6 PM, Monday: 10 AM-7 PM, Tuesday: 10 AM-7 PM, Wednesday: 10 AM-7 PM",
"6th Avenue Aquarium": "Thursday: 11 AM-7 PM, Friday: 11 AM-7 PM, Saturday: 10 AM-7 PM, Sunday: 10 AM-7 PM, Monday: 11 AM-7 PM, Tuesday: 11 AM-7 PM, Wednesday: 11 AM-7 PM",
"Electric Bike Center":"Thursday: 10:30 AM-6 PM, Friday: 10:30 AM-6 PM, Saturday: 10:30 AM-5 PM, Sunday: Closed, Monday: Closed, Tuesday: 10:30 AM-6 PM, Wednesday: 10:30 AM-6 PM",
"Arguello Salon": "Thursday: 10 AM-6:30 PM, Friday: 10 AM-6:30 PM, Saturday: 10 AM-6 PM, Sunday: 10 AM-6:30 PM, Monday: 10 AM-6:30 PM, Tuesday: 10 AM-6:30 PM, Wednesday: 10 AM-6:30 PM",
"DD Cycles":"Thursday: 10:30 AM-6 PM, Friday: 10:30 AM-6 PM, Saturday: 11 AM-6 PM, Sunday: Closed, Monday: Closed, Tuesday: 10:30 AM-6 PM, Wednesday: 10:30 AM-6 PM",
"Eye & Hand Society":"Thursday: 12 PM-7 PM, Friday: 12 PM-7 PM, Saturday: 12 PM-7 PM, Sunday: Closed, Monday: Closed, Tuesday: Closed, Wednesday: 12 PM-7 PM",
"Fela's Discount":"Thursday: 10 AM-6 PM, Friday: 10 AM-6 PM, Saturday: 10 AM-6 PM, Sunday: 10 AM-6 PM, Monday: 10 AM-6 PM, Tuesday: 10 AM-6 PM, Wednesday: 10 AM-6 PM",
"Finer Flora":"Thursday: 9 AM-3 PM, Friday: 9 AM-3 PM, Saturday: Closed, Sunday: Closed, Monday: 9 AM-3 PM, Tuesday: 9 AM-3 PM, Wednesday: 9 AM-3 PM",
"Gable's Stationary":"Thursday: 12 PM-5 PM, Friday: 12 PM-5 PM, Saturday: 11:30 AM-5:30 PM, Sunday: Closed, Monday: 12 PM-5 PM, Tuesday: 12 PM-5 PM, Wednesday: 12 PM-5 PM",
"Geary Shoe Repair":"Thursday: 11 AM-6 PM, Friday: 11 AM-6 PM, Saturday: 11 AM-6 PM, Sunday: Closed, Monday: 11 AM-6 PM, Tuesday: 11 AM-6 PM, Wednesday: 11 AM-6 PM",
"Gaslight & Shadows":"Thursday: 10 AM-7 PM, Friday: 10 AM-7 PM, Saturday: 10 AM-7 PM, Sunday: 9 AM-6 PM, Monday: 10 AM-7 PM, Tuesday: 10 AM-7 PM, Wednesday: 10 AM-7 PM",
"Goldstar Discount":"Thursday: 9:30 AM-7 PM, Friday: 9:30 AM-7 PM, Saturday: 9:30 AM-7 PM, Sunday: 9:30 AM-7 PM, Monday: 9:30 AM-7 PM, Tuesday: 9:30 AM-7 PM, Wednesday: 9:30 AM-7 PM",
"JANE Consignment":"Thursday: 12-7 PM, Friday: 12-7 PM, Saturday: 12-7 PM, Sunday: Closed, Monday: Closed, Tuesday: 12-7 PM, Wednesday: 12-7 PM",
"JVD Global":"Thursday: 12-6 PM, Friday: 12-6 PM, Saturday: 12-6 PM, Sunday: Closed, Monday: 12-6 PM, Tuesday: 12-6 PM, Wednesday: 12-6 PM",
"Love Street Vintage":"Thursday: 11 AM-6 PM, Friday: 11 AM-6 PM, Saturday: 11 AM-6 PM, Sunday: 11 AM-6 PM, Monday: 11 AM-6 PM, Tuesday: Closed, Wednesday: Closed",
"Ornot":"Thursday: 12-6 PM, Friday: 12-6 PM, Saturday: 12-4 PM, Sunday: Closed, Monday: Closed, Tuesday: 12-6 PM, Wednesday: 12-6 PM",
"Paloma":"Thursday: 2-6 PM, Friday: 2-6 PM, Saturday: 2-6 PM, Sunday: Closed, Monday: Closed, Tuesday: Closed PM, Wednesday: 2-6 PM",
"Sparky's Balloons":"Thursday: 10 AM-6 PM, Friday: 10 AM-6 PM, Saturday: 9 AM-6 PM, Sunday: 9 AM-2 PM, Monday: 10 AM-6 PM, Tuesday: 10 AM-6 PM, Wednesday: 10 AM-6 PM",
"The Future Past":"Thursday: 11 AM-5 PM, Friday: 11 AM-5 PM, Saturday: 11 AM-5 PM, Sunday: 11 AM-3 PM, Monday: Closed, Tuesday: Closed, Wednesday: 11 AM-5 PM",
"Uncommon Thrifts": "Thursday: 10 AM-5 PM, Friday: 10 AM-5 PM, Saturday: 11 AM-5:30 PM, Sunday: 11 AM-5:30 PM, Monday: Closed, Tuesday: Closed, Wednesday: Closed",
"Variety Shop":"Thursday: 11:30 AM-5 PM, Friday: 11:30 AM-5 PM, Saturday: 11:30 AM-5 PM, Sunday: 11:30 AM-5 PM, Monday: 11:30 AM-5 PM, Tuesday: 11:30 AM-5 PM, Wednesday: 11:30 AM-5 PM",
"Wishing Well Workshop":"Thursday: 10:30 AM-5:30 PM, Friday: 10:30 AM-5:30 PM, Saturday: 10:30 AM-5:30 PM, Sunday: 10:30 AM-5:30 PM, Monday: 10:30 AM-5:30 PM, Tuesday: 10:30 AM-5:30 PM, Wednesday: 10:30 AM-5:30 PM",
"Wood Goods & Hot Sauce":"Thursday: Closed, Friday: 10:30 AM-4:30 PM, Saturday: 10:30 AM-5 PM, Sunday: 10:30 AM-3 PM, Monday: Closed, Tuesday: Closed, Wednesday: Closed",
"The Drawing Room":"Thursday: 11 AM-7 PM, Friday: 11 AM-7 PM, Saturday: 11 AM-7 PM, Sunday: 11 AM-7 PM, Monday: Closed, Tuesday: Closed, Wednesday: 11 AM-7 PM",
"Aqua Forest Aquarium":"Thursday: 11 AM-6:30 PM, Friday: 11 AM-6:30 PM, Saturday: 11 AM-6:30 PM, Sunday: 12 PM-6 PM, Monday: 11 AM-6:30 PM, Tuesday: Closed, Wednesday: 11 AM-6:30 PM",
"Kinokuniya":"Thursday: 10:30 AM-8 PM, Friday: 10:30 AM-8 PM, Saturday: 10:30 AM-8 PM, Sunday: 10:30 AM-8 PM, Monday: 10:30 AM-8 PM, Tuesday: 10:30 AM-8 PM, Wednesday: 10:30 AM-8 PM",
"ChaTo":"Thursday: 12 PM-6 PM, Friday: 12 PM-6 PM, Saturday: 12 PM-6 PM, Sunday: 12 PM-6 PM, Monday: Closed, Tuesday: Closed, Wednesday: 12 PM-6 PM",
"Mai Do":"Thursday: 11 AM-6:30 PM, Friday: 11 AM-6:30 PM, Saturday: 11 AM-6:30 PM, Sunday: 11 AM-6:30 PM, Monday: 11 AM-6:30 PM, Tuesday: 11 AM-6:30 PM, Wednesday: 11 AM-6:30 PM",
"Food 24 Market":"Thursday: 9 AM-11 PM, Friday: 9 AM-11 PM, Saturday: 9 AM-12 AM, Sunday: 9 AM-12 AM, Monday: 9 AM-11 PM, Tuesday: 9 AM-11 PM, Wednesday: 9 AM-11 PM",
"Trending Houseware":"Thursday: 11 AM-6 PM, Friday: 11 AM-6 PM, Saturday: 11 AM-6 PM, Sunday: 11 AM-6 PM, Monday: 11 AM-6 PM, Tuesday: 11 AM-6 PM, Wednesday: 11 AM-6 PM",
"Olive":"Thursday: 10 AM-9 PM, Friday: 10 AM-9 PM, Saturday: 10 AM-9 PM, Sunday: 9 AM-9 PM, Monday: 10 AM-9 PM, Tuesday: 10 AM-9 PM, Wednesday: 10 AM-9 PM"
};

export const storeLocation = {
"ENGRAFFT": "11th & Clement",
"Clement Nursery": "21st & Clement",
"Aladdin Rugs": "21st & Clement",
"Foggy Notion": "2nd & Clement",
"6th Avenue Aquarium": "6th & Clement",
"Arguello Salon": "2nd & Geary",
"Electric Bike Center":"20th & Geary",
"DD Cycles":"42nd & Balboa",
"Eye & Hand Society":"35th & Balboa",
"Fela's Discount":"8th & Clement",
"Finer Flora":"5th & California",
"Gable's Stationary":"21st & Geary",
"Geary Shoe Repair":"19th & Geary",
"Gaslight & Shadows":"25th & Clement",
"Goldstar Discount":"9th & Clement",
"JANE Consignment":"24th & Clement",
"JVD Global":"8th & Clement",
"Love Street Vintage":"19th & Balboa",
"Ornot":"2nd & Clement",
"Paloma":"45th & Cabrillo",
"Sparky's Balloons":"2nd & Clement",
"The Future Past":"Arguello & Clement",
"Uncommon Thrifts": "19th & Balboa",
"Variety Shop":"7th & Clement",
"Wishing Well Workshop":"4th & Clement",
"Wood Goods & Hot Sauce":"19th & Balboa",
"The Drawing Room":"3rd & Clement",
"ChaTo":"Kinokuniya Building",
"Kinokuniya":"Kinokuniya Building",
"Mai Do":"Kinokuniya Building",
"Aqua Forest Aquarium":"Fillmore & Post",
"Food 24 Market":"6th & Clement",
"Trending Houseware":"6th & Clement",
"Olive":"4th & Clement",
};

export const storeSite = {
  "ENGRAFFT": "https://www.engrafft.com/",
  "Foggy Notion": "https://www.foggy-notion.com/",
  "Electric Bike Center":"https://electricbikeca.com/",
  "DD Cycles":"http://www.ddcycles.com/",
  "Eye & Hand Society":"https://www.eyeandhandsociety.com/",
  "Finer Flora":"https://www.finerflora.com/",
  "Gaslight & Shadows":"https://gaslightshadows.com/",
  "JANE Consignment":"https://www.janesf.com/",
  "Love Street Vintage":"https://lovestreetsf.com/",
  "Ornot":"https://ornotbike.com/",
  "Paloma":"https://paloma-sf.com/",
  "Sparky's Balloons":"https://sparkysballoons.com/",
  "The Future Past":"https://thefuturepaststudio.com/",
  "Wishing Well Workshop":"https://www.wishingwellsf.com/",
  "Wood Goods & Hot Sauce":"https://www.woodgoodsandhotsauce.com/",
  "The Drawing Room":"https://drawingroomsf.com/",
  "ChaTo":"https://www.sfchato.com/",
  "Kinokuniya":"https://united-states.kinokuniya.com/",
  "Mai Do":"https://usa.kinokuniya.com/stores-maido-san-francisco",
  "Aqua Forest Aquarium":"https://aquaforestaquarium.com/",
  };

export const storeLocationLink = {
  "ENGRAFFT": "https://www.google.com/maps/dir/?api=1&destination=ENGRAFFT",
  "Aladdin Rugs":"https://www.google.com/maps/search/?api=1&query=Aladdin+Rugs+and+Mattresses",
  "Clement Nursery":"https://www.google.com/maps/search/?api=1&query=Clement+Nursery",
  "Foggy Notion": "https://www.google.com/maps/dir/?api=1&destination=foggy+notion+san+francisco",
  "6th Ave Aquarium": "https://www.google.com/maps/dir/?api=1&destination=6th+Avenue+Aquarium+SF",
  "Arguello Salon":"https://www.google.com/maps/search/?api=1&query=Arguello+Salon+San+Francisco",
  "Electric Bike Center":"https://www.google.com/maps/search/?api=1&query=Electric+Bike+Center+San+Francisco",
  "DD Cycles":"https://www.google.com/maps/search/?api=1&query=DD+Cycles+San+Francisco",
  "Eye & Hand Society":"https://www.google.com/maps/search/?api=1&query=Eye+and+Hand+Society+San+Francisco",
  "Fela's Discount":"https://www.google.com/maps/dir/?api=1&destination=Fela%27s+Discount",
  "Finer Flora":"https://www.google.com/maps/search/?api=1&query=Finer+Flora",
  "Gable's Stationary":"https://www.google.com/maps/search/?api=1&query=Gables+Office+Supplies+%26+Stationery",
  "Geary Shoe Repair":"https://www.google.com/maps/dir/?api=1&destination=geary+shoe+repair",
  "Gaslight & Shadows":"https://www.google.com/maps/dir/?api=1&destination=gaslight+%26+shadows+antiques",
  "Goldstar Discount":"https://www.google.com/maps/search/?api=1&query=Goldstar+Discount+Store",
  "JANE Consignment":"https://www.google.com/maps/search/?api=1&query=Jane+Consignment",
  "JVD Global":"https://www.google.com/maps/dir/?api=1&destination=JVD+Global+Inc",
  "Love Street Vintage":"https://www.google.com/maps/search/?api=1&query=Love+Street+Vintage",
  "Ornot":"https://www.google.com/maps/dir/?api=1&destination=ornot+sf",
  "Paloma":"https://www.google.com/maps/search/?api=1&query=paloma+outer+richmond",
  "Sparky's Balloons":"https://www.google.com/maps/search/?api=1&query=Sparky's+Balloons",
  "The Future Past":"https://www.google.com/maps/dir/?api=1&destination=The+Future+Past,+San+Francisco,+CA",
  "Uncommon Thrifts": "https://www.google.com/maps/dir/?api=1&destination=Wood+Goods+and+Hot+Sauce",
  "Variety Shop":"https://www.google.com/maps/dir/?api=1&destination=Variety+Shop,+606+Clement+St,+San+Francisco,+CA+94118",
  "Wishing Well Workshop":"https://www.google.com/maps/dir/?api=1&destination=Wishing+Well+Workshop+SF",
  "Wood Goods & Hot Sauce":"https://www.google.com/maps/dir/?api=1&destination=Wood+Goods+and+Hot+Sauce",
  "The Drawing Room":"https://www.google.com/maps/search/?api=1&query=The+Drawing+Room+SF:+Clement+street",
  "ChaTo":"https://www.google.com/maps/dir/?api=1&destination=Cha+To,+San+Francisco",
  "Kinokuniya":"https://www.google.com/maps/dir/?api=1&destination=Kinokuniya,+San+Francisco",
  "Mai Do":"https://www.google.com/maps/dir/?api=1&destination=Mai+Do,+San+Francisco",
  "Aqua Forest Aquarium":"https://www.google.com/maps/dir/?api=1&destination=Aqua+Forest+Aquarium,+San+Francisco",
  "Food 24 Market": "https://www.google.com/maps/dir/?api=1&destination=500+Clement+St,+San+Francisco,+CA+94118",
  "Trending Houseware": "https://www.google.com/maps/dir/?api=1&destination=520+Clement+St,+San+Francisco,+CA+94118",
  "Olive": "https://www.google.com/maps/dir/?api=1&destination=304+Clement+St,+San+Francisco,+CA+94118"
  };

export const storeContact = {
  "6th Avenue Aquarium": "(415) 668-7190",
  "Aladdin Rugs":"(415) 933-6190",
  "Clement Nursery":"(415) 750-3990",
  "Arguello Salon": "(415) 742-4689",
  "DD Cycles": "(415) 752-7980",
  "Electric Bike Center": "(415) 702-6272",
  "ENGRAFFT": "@engrafft",
  "Eye & Hand Society": "@eyeandhandsociety",
  "Fela's Discount": "(929) 888-2195",
  "Finer Flora": "(415) 340-3126",
  "Foggy Notion": "@foggynotionsf",
  "Gable's Stationary": "@gables.sf",
  "Gaslight & Shadows": "@gaslight_shadows",
  "Geary Shoe Repair": "(415) 387-1268",
  "Goldstar Discount": "(415) 221-5052",
  "JANE Consignment": "@janeinsf",
  "JVD Global": "(415) 387-3666",
  "Love Street Vintage": "@lovestreetsf",
  "Ornot": "@ornotbike",
  "Paloma": "@paloma_sanfrancisco",
  "Sparky's Balloons": "@sparkysballoons",
  "The Future Past": "@_the_future_past_",
  "Uncommon Thrifts": "@uncommonthrifts",
  "Variety Shop": "(415) 800-1483",
  "Wishing Well Workshop": "@wishingwellsf",
  "Wood Goods & Hot Sauce": "@woodgoodsandhotsauce",
  "The Drawing Room": "@drawingroomsf",
  "ChaTo":"@sfchato",
  "Kinokuniya":"(415) 567-7625",
  "Mai Do":"@maido_sf2f",
  "Aqua Forest Aquarium":"(415) 929-8883",
  "Food 24 Market":"(415) 221-2231",
  "Trending Houseware":"(415) 810-1378",
  "Olive":"(628) 280-2624",
};

export const daysSinceArrival = 21;

export const searchResultsPerPage = 36;
export const featuredItemsPerWidget = 28;

export const featureableCategories = [
"Apothecary", 
"Gardening",
"Flowers", 
"Apparel",
"Bathroom",
"Holidays",
"Accessories",
"Jewelry",
"Art",
"Grocery",
"Electronics",
];

export const availableNeighborhoods = [
"The Richmond",
"Outer Richmond", 
"Japantown" 
];

export const featureableStores = [
"ENGRAFFT", 
"Wood Goods & Hot Sauce",
"Foggy Notion",
"6th Avenue Aquarium",
"Paloma",
"Eye & Hand Society",
"The Drawing Room",
"JVD Global",
"Wishing Well Workshop",
"Olive",
"Mai Do",
];

export const orderOnline = [
"Sparky's Balloons",
"Foggy Notion",
"ENGRAFFT",
"Ornot",
"Paloma",
"Eye & Hand Society"
];


export const inStoreOnly = [
"The Drawing Room",
"Aladdin Rugs",
"Aqua Forest Aquarium",
"Clement Nursery",
"DD Cycles",
"ChaTo",
"Electric Bike Center",
"Fela's Discount",
"Finer Flora",
"Gaslight & Shadows",
"Kinokuniya",
"Love Street Vintage",
"JANE Consignment",
"The Future Past",
"Uncommon Thrifts",
"Wishing Well Workshop"
];



function TrackPageViews() {
const location = useLocation();

useEffect(() => {
  // Check if gtag function is available
trackPageView(location.pathname + location.search)

}, [location]);

return null;

}

console.log(`Environment: ${process.env.NODE_ENV}`);

function App() {
  return (
    <Router>
      <GlobalProvider>
      <TrackPageViews />
        <Routes>
          <Route path="/" element={<Explore />} />
          <Route path="/:itemSlug" element={<ItemDetails />} />
          <Route path="/success" element={<Explore showSuccessOverlay={true} />} />
        </Routes>
      </GlobalProvider>
    </Router> 
  );
}

export default App;