import React, { useContext, useState } from 'react';
import { formatPrice } from '../4_utils/f_FormatPrice';
import styles from './j_Item.module.css';
import { daysSinceArrival } from '../App';
import time_icon from '../7_icons/time_icon.svg';
import sale_icon from '../7_icons/sale_icon.svg';
import { useSearchParams } from 'react-router-dom';
import { Scroll } from '../4_utils/h_ScrollToTop';
import { trackEvent } from '../4_utils/i_GoogleAnalytics.js';
import EnlargedItem from './k_EnlargedItem.js';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '../3_context/a_GlobalContext.js';

function Item({ item, setStoreFilters }) {

  const [searchParams, setSearchParams] = useSearchParams();
  const [overlayState, setOverlayState] = useState(null);
  const navigate = useNavigate();
  const { setCategoryFilters,
          setNewArrivalFilter,
          setDeliverableFilter,
          setSaleFilter,
          storeFilters,
          categoryFilters,
          saleFilter,
          newArrivalFilter,
          deliverableFilter,
          currentLocation,
          query
  } = useContext(GlobalContext);

  const createSlug = (itemName, itemId) => {
    return `${itemName.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/gi, '')}-${itemId}`;
  };

// Save latest filters for "Continue Shopping" button functionality
  const saveSearchParams = () => {
    const searchParams = {
      stores: storeFilters,
      categories: categoryFilters,
      newArrival: newArrivalFilter,
      sale: saleFilter,
      deliverable: deliverableFilter,
      location: currentLocation,
      query: query
    };
    console.log('searchParams:', searchParams)
    localStorage.setItem('searchParams', JSON.stringify(searchParams));
  };
  
  const handleItemClick = () => {
    trackEvent('item_detail_click', {
      'event_category': 'Item Image Click',
      'store_name': item.store,
      'item_name' : item.item,
      'value': 1,
      'send_to': 'G-W17DEQW0DE'
    });
    const itemSlug = createSlug(item.item, item.objectID);
    saveSearchParams();
    
    // Then removes filters upon clicking an item
    setStoreFilters([])
    setCategoryFilters([])
    setSaleFilter(false)
    setNewArrivalFilter(false)
    setDeliverableFilter(false)
    navigate(`/${itemSlug}`, { state: { item } });
  };

  // Save latest filters for "Continue Shopping" button functionality

  

  const closeOverlay = () => {
    setOverlayState(null);
  };


  const handleStoreClick = (item) => {
    // Set the filters state to only include the clicked store
    setStoreFilters([item.store]);
    
    trackEvent('select_store', {
      'event_category': 'Store Clicks',
      'store_name': item.store,
      'value': 1,
      'send_to': 'G-W17DEQW0DE'
    });
    // Update the URL to reflect only the clicked store
    searchParams.set('stores', item.store);
    searchParams.delete('page'); // Remove page parameter, if you have pagination
    searchParams.delete('categories'); // Remove page parameter, if you have pagination
    searchParams.delete('query'); 
    setSearchParams(searchParams);
    Scroll()
  };

  const currentTimestamp = Math.floor(Date.now() / 1000);

  // Calculate the number of days since the item's timestamp
  const daysAgo = Math.floor((currentTimestamp - item.timestamp) / (24 * 60 * 60));
  const isNew = daysAgo < daysSinceArrival;

  const discountPercentage = item.maxPrice && item.maxPrice > 0 
    ? Math.round(((item.maxPrice - item.minPrice) / item.maxPrice) * 100)
    : 0; // Ensure there's no division by zero or negative values
  

  return (
    <div className={styles.container}>
      <div className={styles.imagewrapper} onClick={() => handleItemClick(item.image, item)}>
        <div className={styles.flagscontainer}>
            {item.isOnSale === true && (
                <div className={styles.saleflag}>
                    <img src={sale_icon} alt="Sale" className={styles.saleicon}/>
                    {discountPercentage > 0 ? `${discountPercentage}% Off` : 'No Discount'}
                </div>
            )}
            {isNew && (
                <div className={item.isOnSale ? styles.newsaleflag : styles.newflag}>
                    <img src={time_icon} alt="New" className={styles.timeicon} />
                    {daysAgo === 0 ? 'Added Today' : `Added ${daysAgo} day${daysAgo > 1 ? 's' : ''} ago`}
                </div>
            )}
        </div>
        <img src={item.image} alt={item.store} className={styles.itemimage} />
    </div>


      <div className={styles.details}>
            <div className={styles.storenamewrapper}>
              <div className={styles.storename} onClick={() => handleStoreClick(item)} >{item.store}</div>
            </div>
            <div className={styles.itemname} onClick={() => handleItemClick(item)}>{item.item}</div>
            <div className={styles.itempricecontainer} onClick={() => handleItemClick(item)}>
                {formatPrice(item.minPrice, item.maxPrice, item.isOnSale, styles)}
            </div>
      </div>

      {overlayState && (
        <EnlargedItem
          overlayState={overlayState}
          closeOverlay={closeOverlay}
        />
      )}

    </div>
  );
}

export default Item;
