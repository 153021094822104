import React, { useEffect, useState, useContext } from 'react';
import styles from './b_LandingWidgets.module.css';
import { fetchSaleItems } from '../../../5_services/a_Algolia/6_FetchSaleItems';
import { fetchNewArrivals } from '../../../5_services/a_Algolia/5_FetchNewArrivals';
import { fetchFeaturedItems } from '../../../5_services/a_Algolia/4_FetchFeaturedItems';
import { fetchDeliverableItems } from '../../../5_services/a_Algolia/7_FetchDeliverableItems';
import { GlobalContext } from '../../../3_context/a_GlobalContext';
import { shuffleArray } from '../../../4_utils/a_ShuffleArray';
import { featureableCategories, featureableStores } from '../../../App';
import ItemsWidget from './d_ItemsWidget';
import { throttle } from '../../../4_utils/j_ThrottleUseEffect';
import { trackEvent } from '../../../4_utils/i_GoogleAnalytics';
import WelcomeWidget from './g_WelcomeWidget';
import { featuredItemsPerWidget } from '../../../App';
import AboutCharm from './m_AboutCharmWidget';


function LandingWidgets() {
  const [featuredStores, setFeaturedStores] = useState([]);
  const [storeItems, setStoreItems] = useState({});
  const [featuredCategories, setFeaturedCategories] = useState([]);
  const [categoryItems, setCategoryItems] = useState({});
  const [newArrivals, setNewArrivals] = useState([]);
  const [saleItems, setSaleItems] = useState([]);
  const [deliverableItems, setDeliverableItems] = useState([]);
  const [totalItems, setTotalItems] = useState({});
  const [shopNow, setShopNow] = useState(true);


  const { availableStores,
          availableCategories,
          currentLocation,
          storeFilters,
          categoryFilters,
          setShowSaleItemsInFilters,
          setShowNewItemsInFilters,
          setShowDeliverableItemsInFilters
         } = useContext(GlobalContext);

  // CROSS REFERENCE FEATUREABLE STORES AND CATEGORIES WITH THOSE THAT ARE AVAILABLE
  function processFeaturedItems(availableItems, featureableItems, setFeaturedFunction) {
    const eligibleItems = availableItems.filter(item => featureableItems.includes(item));
    const shuffledItems = shuffleArray(eligibleItems);
    setFeaturedFunction(shuffledItems.slice(0, 2));
  }

  
  
  useEffect(() => {
    processFeaturedItems(availableStores, featureableStores, setFeaturedStores);
    processFeaturedItems(availableCategories, featureableCategories, setFeaturedCategories);
  // eslint-disable-next-line  
  }, [availableStores/*, availableCategories*/]);

  const [resultsPerPage, setResultsPerPage] = useState(window.innerWidth <= 800 ? featuredItemsPerWidget : 4)

  useEffect(() => {
    const handleResize = () => {
      if ((window.innerWidth <= 800 && resultsPerPage !== featuredItemsPerWidget) ||
          (window.innerWidth > 800 && resultsPerPage !== 4)) {
        setResultsPerPage(window.innerWidth <= 800 ? featuredItemsPerWidget : 4);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [resultsPerPage]);



  useEffect(() => {
  const fetchAndSetItems = (featuredItems, setItemsFunction, facet) => {
    featuredItems.forEach((item) => {
      if (item) {
        fetchFeaturedItems(facet, item, availableStores, featureableStores, resultsPerPage)
          .then(({ items, total }) => {
            if (items && items.length > 0) {
              const uniqueStores = new Set();
              items.forEach(item => uniqueStores.add(item.store));
              uniqueStores.forEach(store => {
                trackEvent('featured_store_surface', {
                  'event_category': 'Unique Stores On Featured Widgets',
                  'store_name': store,
                  'value':1,
                  'send_to': 'G-W17DEQW0DE'
                });
              });
            }
            const shuffledItems = shuffleArray(items);
            setItemsFunction(prevItems => ({
              ...prevItems,
              [item]: shuffledItems
            }));
            setTotalItems(prevTotal => ({
              ...prevTotal,
              [item]: total
            }));
          })
          .catch(error => console.error(`Error fetching items for ${item}:`, error));
      }
    });
  }

  fetchAndSetItems(featuredStores, setStoreItems, "store");
  fetchAndSetItems(featuredCategories, setCategoryItems, "category");
  // eslint-disable-next-line
  }, [featuredStores, /*featuredCategories, availableStores*/]);  


  const throttledFetchAndSetSaleItems = throttle(function() {
    fetchSaleItems(availableStores, resultsPerPage, featureableStores)
      .then(({ items, total }) => {
        const shuffledItems = shuffleArray(items);
        setSaleItems(shuffledItems);
        if (shuffledItems.length < 1) {
          setShowSaleItemsInFilters(false);
        } else {
          setShowSaleItemsInFilters(true);
          if (shuffledItems && shuffledItems.length > 0) {
            const uniqueStores = new Set();
            shuffledItems.forEach(item => uniqueStores.add(item.store));
            uniqueStores.forEach(store => {
              trackEvent('featured_store_surface', {
                'event_category': 'Unique Stores On Featured Widgets',
                'store_name': store,
                'value':1,
                'send_to': 'G-W17DEQW0DE'
              });
            });
          }
        }
        setTotalItems(prevTotal => ({
          ...prevTotal,
          'On Sale Now': total
        }));
      })
      .catch(error => console.error(`Error fetching sale items:`, error));
  }, 0)



  // Sets limit to only one run in 500 ms
  const throttledFetchAndSetNewArrivals = throttle(function() {
    fetchNewArrivals(availableStores, resultsPerPage)
        .then(({ items, total }) => {
          const shuffledItems = shuffleArray(items);
          setNewArrivals(shuffledItems);
          // Use the local 'items' variable to check the length
          if (shuffledItems.length < 1) {
            setShowNewItemsInFilters(false);
          } else {
            setShowNewItemsInFilters(true);
            // Track unique stores that show up for each of the featured widgets (similar to event in SearchResults.js )
            if (shuffledItems && shuffledItems.length > 0) {
              const uniqueStores = new Set();
              shuffledItems.forEach(item => uniqueStores.add(item.store));
              uniqueStores.forEach(store => {
                trackEvent('featured_store_surface', {
                  'event_category': 'Unique Stores On Featured Widgets',
                  'store_name': store,
                  'value':1,
                  'send_to': 'G-W17DEQW0DE'
                });
              });
            }
          }
          setTotalItems(prevTotal => ({
            ...prevTotal,
            'New Arrivals': total
          }));
        })
        .catch(error => console.error(`Error fetching new arrivals:`, error));
  }, 500)

  const throttledFetchAndSetDeliverableItems = throttle(function() {
    fetchDeliverableItems(availableStores, resultsPerPage)
      .then(({ items, total }) => {
        const shuffledItems = shuffleArray(items);
        setDeliverableItems(shuffledItems);
        if (shuffledItems.length < 1) {
          setShowDeliverableItemsInFilters(false);
        } else {
          setShowDeliverableItemsInFilters(true);
          if (shuffledItems && shuffledItems.length > 0) {
            const uniqueStores = new Set();
            shuffledItems.forEach(item => uniqueStores.add(item.store));
            uniqueStores.forEach(store => {
              trackEvent('featured_store_surface', {
                'event_category': 'Unique Stores On Featured Widgets',
                'store_name': store,
                'value':1,
                'send_to': 'G-W17DEQW0DE'
              });
            });
          }
        }
        setTotalItems(prevTotal => ({
          ...prevTotal,
          [deliveryWidgetTitle]: total
        }));
      })
      .catch(error => console.error(`Error fetching deliverable items:`, error));
  }, 0)

  
  // FETCH SALE ITEMS
  useEffect(() => {
    throttledFetchAndSetSaleItems();
    // eslint-disable-next-line
  }, [availableStores]);

  // FETCH NEW ARRIVALS
  useEffect(() => {
    throttledFetchAndSetNewArrivals();
    // eslint-disable-next-line
  }, [availableStores]);

  // FETCH DELIVERABLE ITEMS
  useEffect(() => {
    throttledFetchAndSetDeliverableItems();
    // eslint-disable-next-line
  }, [availableStores]);


  const now = new Date();

    // Utilize Intl.DateTimeFormat to get the time in 'America/Los_Angeles' timezone
    const formatter = new Intl.DateTimeFormat('en-US', {
        timeZone: 'America/Los_Angeles',
        hour: 'numeric',
        hour12: false,
    });

    // Extract the hour in PST/PDT timezone
    const pstHour = parseInt(formatter.format(now), 10);

    // Determine the delivery message based on the current PST/PDT hour
    const deliveryWidgetTitle = pstHour < 15 // Adjusted to check for before 4 PM PST/PDT
      ? "Delivery Available"
      : "Delivery Available";


  return (
    <div className={styles.container}>
      {/* WELCOME WIDGET */}
      {
        currentLocation === "Select Location" && storeFilters.length === 0 && categoryFilters.length === 0 && shopNow === true && (
          <WelcomeWidget setShopNow={setShopNow}/>
        )
      }
      
      {/* SALE ITEMS WIDGET */}
      {saleItems.length > 0 && (
        <>
        <ItemsWidget type ={"sale"} title={"On Sale Now"} items={saleItems} totalItems={totalItems}/>
        </>        
      )}
      {/* NEW ARRIVALS ITEMS WIDGET */}
      {newArrivals.length > 0 && (
        <>
          <ItemsWidget type ={"new"} title={"New Arrivals"} items={newArrivals} totalItems={totalItems} />
        </>
      )}
      {/* DELIVERABLE ITEMS WIDGET */}
      {deliverableItems.length > 0 && (
        <>
        <ItemsWidget type ={"deliverable"} title={deliveryWidgetTitle} items={deliverableItems} totalItems={totalItems}/>
        </>        
      )}

      
        
      {/* FEATURED STORES ITEMS WIDGETS */}
      { featuredStores.some(store => (storeItems[store] || []).length > 0) && (
      <div className={styles.landingwidgettitle}>
        Featured Stores
      </div>
      )}
      {featuredStores.map((store, index) => {
        const items = storeItems[store] || [];
        if (!items.length) return null;
        return (
          <ItemsWidget key={index} type ={"store"} title={store} items={items} totalItems={totalItems}/>     
        );
      })}

      {/* ABOUT CHARM WIDGET */}
      <AboutCharm/>
      
      {/* FEATURED CATEGORIES ITEMS WIDGETS */}
      { featuredCategories.some(category => (categoryItems[category] || []).length > 0) && (
        <>
          <div className={styles.landingwidgettitle}>
            Popular Categories
          </div>
        </>
      )}
      {featuredCategories.map((category, index) => {
        const items = categoryItems[category] || [];
        if (!items.length) return null;
        return (
          <ItemsWidget key={index} type ={"category"} title={category} items={items} totalItems={totalItems}/>
        );
      })}
    </div>
  );
}

export default LandingWidgets;
