import algoliasearch from 'algoliasearch/lite';
import { searchResultsPerPage, daysSinceArrival } from '../../App';

let counter = 0;

export async function fetchSearchResultsWithinNeighborhood(query, neighborhood, storeFilters, categoryFilters, saleFilter, newArrivalFilter, deliverableFilter, page, sort) {
  counter++;
  console.log("fetchSearchResultsWithinNeighborhood " + counter);
  console.log("Running fetchSearchResultsWithinNeighborhood with", { query, neighborhood, storeFilters, categoryFilters, sort, saleFilter, newArrivalFilter, deliverableFilter });

  let algoliaClient;
  let index;

  if (sort === "Sort") {
    algoliaClient = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID, process.env.REACT_APP_ALGOLIA_API_KEY_MAIN);
    index = algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_INDEX_NAME_MAIN);
  } else if (sort === "Price: Low to High") {
    algoliaClient = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID, process.env.REACT_APP_ALGOLIA_API_KEY_SORT_CHEAP);
    index = algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_INDEX_NAME_SORT_CHEAP);
  } else if (sort === "Price: High to Low") {
    algoliaClient = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID, process.env.REACT_APP_ALGOLIA_API_KEY_SORT_EXPENSIVE);
    index = algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_INDEX_NAME_SORT_EXPENSIVE);
  }

  try {
    // Construct facet filters for Algolia
    const storeFacets = storeFilters.map(filter => `store:${filter}`);
    const categoryFacets = categoryFilters.map(filter => `category:${filter}`);
    let facetFilters = [];
    let numericFilters = [];

    // Add neighborhood filter
    if (neighborhood) {
      facetFilters.push([`neighborhood:${neighborhood}`]);
    }

    if (storeFacets.length > 0) facetFilters.push(storeFacets);
    if (categoryFacets.length > 0) facetFilters.push(categoryFacets);

    // Add isOnSale filter if saleFilter is true
    if (saleFilter) {
      facetFilters.push([`isOnSale:true`]);
    }

    // Add new arrivals filter if newArrivalFilter is true
    if (newArrivalFilter) {
      const daysAgo = Math.floor(Date.now() / 1000) - (daysSinceArrival * 24 * 60 * 60);
      numericFilters.push(`timestamp>${daysAgo}`);
    }

     // Add isDeliverable filter if deliverableFilter is true
     if (deliverableFilter) {
      facetFilters.push([`isDeliverable:true`]);
    }

    const response = await index.search(query, {
      hitsPerPage: searchResultsPerPage,
      page,
      facetFilters: facetFilters,
      numericFilters: numericFilters
    });

    return { items: response.hits, total: response.nbHits };
  } catch (error) {
    console.error(`Error fetching search results within neighborhood`, error);
    return { items: [], total: 0 };
  }
}
