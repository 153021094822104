import React, { useState } from 'react';
import styles from './f_Slider.module.css';

function Slider({ label, min, max, increment, currentValue, onSliderChange }) {
  const [background, setBackground] = useState(`linear-gradient(to right, var(--primary-color-dark) 50%, var(--primary-color-light) 50%)`);
  const [internalValue, setInternalValue] = useState(currentValue);

  const handleSliderChange = (event) => {
    const newValue = parseFloat(event.target.value);
    setInternalValue(newValue); // Update internal state for the thumb position
    const percentage = ((newValue - min) / (max - min)) * 100;
    const newBackground = `linear-gradient(to right, var(--primary-color-dark) ${percentage}%, var(--primary-color-light) ${percentage}%)`;
    setBackground(newBackground); // Update the visual feedback in real-time
  };

  // This function commits the value once the user stops interacting
  const handleSliderChangeCommitted = () => {
    onSliderChange(internalValue); // Only this should trigger the parent's state update
  };

  return (
    <div className={styles.container}>
      <span className={styles.leftlabel}>{label}</span>
      <input
        className={styles.input}
        type="range"
        min={min}
        max={max}
        step={increment}
        value={internalValue}
        onChange={handleSliderChange}
        onMouseUp={handleSliderChangeCommitted}
        onKeyUp={handleSliderChangeCommitted}
        onTouchEnd={handleSliderChangeCommitted}
        style={{ background: background }}
      />
      <span className={styles.rightlabel}>{internalValue} mi</span>
    </div>
  );
}

export default Slider;
