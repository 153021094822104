import React, { useContext, useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import styles from './b_Body.module.css';
import { fetchSelectedItemDetails } from '../3_services/1_FetchSelectedItemDetails';
import { GlobalContext } from '../../../3_context/a_GlobalContext';
import { formatPrice } from '../../../4_utils/f_FormatPrice';
import { addToCart } from '../../../4_utils/k_AddToCart';
import LoadingIndicator from '../../../2_common/g_LoadingIndicator';
import check_icon from '../../../7_icons/check_icon.svg'; // Import your phone icon
import purplecheck_icon from '../../../7_icons/purplecheck_icon.svg'; // Import your phone icon
import back_icon from '../../../7_icons/back_icon.svg'; // Import your phone icon
import plus_icon from '../../../7_icons/plus_icon.svg'; // Import your plus icon
import minus_icon from '../../../7_icons/minus_icon.svg'; // Import your minus icon
import { trackEvent } from '../../../4_utils/i_GoogleAnalytics';
import { checkoutRedirectItemDetails } from '../../../5_services/c_Stripe/1_CheckoutRedirectItemDetails';
import { storeLocationLink } from '../../../App';
import { getDeliveryDay} from '../../../4_utils/o_StoreHourUtils';
import { storeHours } from '../../../App';

function Body() {
    const { 
        showTopBannerSearchBar,
        isMobile, 
        setCartItems, 
        cartItems
        } = useContext(GlobalContext);

    const [item, setItem] = useState(null);
    const { itemSlug } = useParams();
    console.log(itemSlug)
   
    const inputRef = useRef(null); 
    const [ itemSpecifics, setItemSpecifics ] = useState("");
    const isInCart = (itemId) => cartItems.some(item => item.id === itemId);
    const [checkoutLoading, setCheckoutLoading] = useState(false);
    const [quantity, setQuantity] = useState(1); // Default quantity
   


    const incrementQuantity = () => {
        setQuantity(prevQuantity => prevQuantity + 1);
    };
    
    const decrementQuantity = () => {
        setQuantity(prevQuantity => Math.max(1, prevQuantity - 1)); // Ensure quantity doesn't go below 1
    };

    useEffect(() => {
        const existingCartItem = cartItems.find(cartItem => cartItem.id === item?.objectID); // Use item?.objectID for safety
        if (existingCartItem) {
            setQuantity(existingCartItem.quantity);  // Update local quantity if item is in global cart
        }
    }, [cartItems, item?.objectID]);


    useEffect(() => {
        console.log(itemSpecifics)
    }, [itemSpecifics]);


    useEffect(() => {
        const fetchDetails = async () => {
            const objectID = itemSlug.substring(itemSlug.lastIndexOf('-') + 1);
            console.log(objectID)
            try {
                const details = await fetchSelectedItemDetails(objectID);
                setItem(details);
            } catch (error) {
                console.error('Error fetching item details:', error);
            }
        };

        if (itemSlug) {
            fetchDetails();
        }
    }, [itemSlug]);

    const handleAddToCart = () => {
        const cartItem = {
            id: item.objectID,
            name: item.item,
            price: item.minPrice,
            maxprice: item.maxPrice,
            sale: item.isOnSale,
            image: item.image,
            specifics: itemSpecifics,
            store: `From ${item.store} in ${item.neighborhood}`,
            delivery: deliveryInfo,
            quantity: quantity // Use the state variable
        };
        addToCart(cartItem, setCartItems, quantity);
        // Call the conversion tracking function after adding to cart successfully
        if (typeof window.gtag_report_conversion === 'function') {
            window.gtag_report_conversion(null, 'add_to_cart'); // You can replace 'window.location.href' with the URL you want users to be redirected after adding to cart, if any.
        }
    };

    useEffect(() => {
        const existingCartItem = cartItems.find(item => item.id === item.objectID);
        if (existingCartItem) {
            setQuantity(existingCartItem.quantity);  // Update local quantity if item is in global cart
        } else {
            setQuantity(1);  // Reset to 1 if item is not in the cart
        }
    }, [cartItems]); 

    const handleCheckout = async () => {
        setCheckoutLoading(true);
        setTimeout(() => {
            setCheckoutLoading(false);
        }, 2000);

        if (typeof window.gtag_report_conversion === 'function') {
            window.gtag_report_conversion(null, 'checkout_redirect'); // You can replace 'window.location.href' with the URL you want users to be redirected after adding to cart, if any.
        }

        checkoutRedirectItemDetails(item, itemSpecifics, deliveryInfo, quantity)

        trackEvent('order_form_click', {
          'event_category': 'Click on order form',
          'store_name': item.store,
          'item_name': item.item,
          'value': 1,
          'send_to': 'G-W17DEQW0DE'
        });
        // trackConversion()
      };


    const continueShopping = () => {
        const savedParams = JSON.parse(localStorage.getItem('searchParams'));
        // Changes the URL to match what localStorage had before clicking on the item
        if (savedParams) {
            const searchParams = new URLSearchParams();

            if (savedParams.stores && savedParams.stores.length > 0) {
                searchParams.set('stores', savedParams.stores.join(','));
            }
            if (savedParams.categories && savedParams.categories.length > 0) {
                searchParams.set('categories', savedParams.categories.join(','));
            }
            if (savedParams.newArrival === true) {
                searchParams.set('newArrival', 'true');
            };
            if (savedParams.sale === true) {
                searchParams.set('sale', true);
                
            }
            if (savedParams.deliverable === true) {
                searchParams.set('deliverable', true);
            }
            if (savedParams.location && savedParams.location !== 'Select Location') {
                searchParams.set('location', savedParams.location);
            }
            if (savedParams.query && savedParams.query !== '') {
                searchParams.set('query', savedParams.query);
            }
            // Navigate back with the constructed searchParams
            const paramsString = searchParams.toString();
        if (paramsString) {
            window.location.href = `/?${paramsString}`;
        } else {
            // If no params or params are empty, navigate to root without /?
            window.location.href = '/';
        }
    } else {
        // Fallback if no params were saved
        window.history.back();
    }
    };

    const handleInStoreOnly = () => {
        const url = storeLocationLink[item.store]; // Adjust if 'storeLocationLink' or 'item.store' differ in your actual code
        if (isMobile) { // Here 'isMobile' is now directly used from your context
            // Open in the same tab for mobile devices
            window.location.href = url;
        } else {
            // Open in a new tab for desktop
            window.open(url, '_blank');
        }
    };

    const handleOrderOnline = () => {
        const url = item.info; // Adjust if 'storeLocationLink' or 'item.store' differ in your actual code
        if (isMobile) { // Here 'isMobile' is now directly used from your context
            // Open in the same tab for mobile devices
            window.location.href = url;
        } else {
            // Open in a new tab for desktop
            window.open(url, '_blank');
        }
    };


    // SET THE DELIVERY INFO FOR THE ITEM
    const [deliveryInfo, setDeliveryInfo] = useState('');

    useEffect(() => {
        if (item && storeHours) { // Make sure item and storeHours are not null or undefined
            const deliveryDay = getDeliveryDay(item.store, storeHours);
            setDeliveryInfo("Delivered by " + deliveryDay);
        }
    }, [item]);
    

    return (
        <>
            {/* Display item details or a loading indicator as needed */}
            {item ? (
                <div className={`${styles.container} ${isMobile && !showTopBannerSearchBar ? styles.togglemobilebottom : ''}`}>
                    <div className={styles.leftcontent}>
                        <img className={styles.image} src={item.image} alt="Item" />
                    </div>
                    <div className={styles.rightcontent}>
                        <div>{formatPrice(item.minPrice, item.maxPrice, item.isOnSale, styles)}</div>
                        <div className={styles.itemname}>{item.item}</div>
                        <div className={styles.storeinfo}> You're supporting {item.store} in {item.neighborhood}</div>
                        {item.description && item.description.trim() !== "" && (
                            <div className={styles.itemdescription}>{item.description}</div>
                        )}
                        <div className={styles.emissions}>Choosing local prevents an estimated 1-5 kg of CO2e emissions per purchase (based on average emissions from long-distance freight, air and road transport)</div>
                        
                        {item.isDeliverable ? (
                            <>
                                <div 
                                    className={styles.itemspecificswrapper}
                                    style={{ opacity: isInCart(item?.objectID) ? 0.7 : 1 }} // Change opacity based on item's presence in the cart                           
                                >                           
                                    <input
                                        type="text"
                                        className={`${styles.itemspecifics} ${isInCart(item?.objectID) ? styles.readonly : ''}`}
                                        ref={inputRef}
                                        placeholder={isInCart(item?.objectID) ? "Item added successfully" : "If needed, add details (size, color, etc)"}
                                        value={itemSpecifics}
                                        onChange={e => setItemSpecifics(e.target.value)}
                                        readOnly={isInCart(item?.objectID)}
                                    />
                                                                
                                    {isInCart(item?.objectID) ? 
                                    <img src={purplecheck_icon} alt="Item Added" /> : // If greencheck_icon is a path to an image
                                    <div className={styles.optional}>
                                        optional
                                    </div>
                                    }
                                </div>
                                <div className={styles.deliverytext}>
                                {deliveryInfo}
                                </div>
                    
                                <div className={styles.buttons}>
                                    <div className={styles.quantity}>
                                        <img 
                                            src={minus_icon} 
                                            alt="Decrease" 
                                            className={styles.quantitybutton} 
                                            style={{ opacity: isInCart(item.objectID) || quantity === 1 ? 0.5 : 1, cursor: isInCart(item.objectID) || quantity === 1 ? 'auto' : 'pointer' }}
                                            onClick={() => !isInCart(item.objectID) && quantity > 1 && decrementQuantity()} // Only allow click if quantity is more than 1 and item is not in cart
                                        />
                                        <div 
                                            className={styles.quantity} 
                                            style={{ opacity: isInCart(item?.objectID) ? 0.5 : 1 }}
                                        >
                                            {isInCart(item?.objectID) ? cartItems.find(cartItem => cartItem.id === item?.objectID)?.quantity : quantity}
                                        </div>
                                        <img 
                                            src={plus_icon} 
                                            className={styles.quantitybutton} 
                                            alt="Increase" 
                                            style={{ opacity: isInCart(item.objectID) ? 0.5 : 1, cursor: isInCart(item.objectID) ? 'auto' : 'pointer' }}
                                            onClick={() => !isInCart(item.objectID) && incrementQuantity()} // Only allow click if item is not in cart
                                        />
                                    </div>
                                    <div 
                                        className={`${styles.addtocart} ${isInCart(item.objectID) ? styles.added : ''}`} 
                                        onClick={() => !isInCart(item.objectID) && handleAddToCart()}
                                    >
                                        {isInCart(item.objectID) ? (
                                            <>
                                                Added <img src={check_icon} alt="Checked" className={styles.checkicon} />
                                            </>
                                        ) : (
                                            'Add To Cart'
                                        )}
                                    </div>
                                    {checkoutLoading ? (
                                        <LoadingIndicator style={{ marginTop: '5px', marginLeft: '5px', width: '20px', height: '20px' }} />
                                    ) : isInCart(item.objectID) ? (
                                        <div className={styles.continueshopping} onClick={continueShopping}>Back <img src={back_icon} alt="Checked" className={styles.backicon} /></div>
                                    ) : (
                                        <div className={styles.buynow} onClick={handleCheckout}>Buy Now</div>
                                    )}
                                </div>
                            </>
                            ) : (
                   
                                <div className={styles.otherbuttons}>
                                    <div 
                                        className={styles.instoreonly}
                                        onClick={handleInStoreOnly}
                                    >
                                        Locate Store
                                    </div>

                                    {item.info !== "no website" && (
                                        <div 
                                            className={styles.instoreonly}
                                            onClick={handleOrderOnline}
                                        >
                                            Order Online
                                        </div>
                                    )}
                                </div>
                                    
                
                            )}

                        <div className={styles.emissionsmobile}>Choosing local prevents an estimated 1-5 kg of CO2e emissions per purchase (based on average emissions from long-distance freight, air and road transport)</div>
                        
                       
                    </div>
                    
                </div>
            ) : (
                <LoadingIndicator style={{ marginTop: '35px', marginLeft: '5px', width: '20px', height: '20px' }} />
            )}
        </>
    );
    }

export default Body;
