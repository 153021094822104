// Import the necessary hook
import React from 'react';

import TopBanner from '../../../2_common/w_TopBanner';
import BottomBanner from '../../../2_common/a_BottomBanner';
import styles from './b_ItemDetails.module.css';
import Body from '../2_Body/a_Body';

const ItemDetails = () => {
  

  return (
    <>
      <div className={styles.container}>
        <TopBanner />
          <div className={styles.body}>
            <Body />
          </div>
        <BottomBanner />
      </div>
    </>
  );
}
  
export default ItemDetails;
