import React, { useState, useContext, useRef } from 'react';
import styles from './z_Filters.module.css';
import { GlobalContext } from '../3_context/a_GlobalContext';
import { useOnClickOutside } from '../4_utils/g_CloseOverlay';
import MobileBackdrop from './o_MobileBackdrop';
import CloseBackdrop from './u_CloseBackdrop';
import { useSearchParams } from 'react-router-dom';
import { Scroll } from '../4_utils/h_ScrollToTop';
import LoadingIndicator from './g_LoadingIndicator';
import { trackEvent } from '../4_utils/i_GoogleAnalytics';
import { useNavigate, useLocation } from 'react-router-dom';


function Filters() {

  const {
    availableCategories,
    availableStores,
    storeFilters, setStoreFilters,
    categoryFilters, setCategoryFilters,
    saleFilter, setSaleFilter,
    newArrivalFilter, setNewArrivalFilter,
    deliverableFilter, setDeliverableFilter,
    loading,
    distanceError,
    setShowFilters,
    showFilters,
    showSaleItemsInFilters,
    showNewItemsInFilters,
    showDeliverableItemsInFilters,
  } = useContext(GlobalContext);

  const [seeMoreCategories, setSeeMoreCategories] = useState(false);
  const [seeMoreStores, setSeeMoreStores] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();





  const filtersRef = useRef(null); 
  let [ searchParams, setSearchParams] = useSearchParams();
  
  //ONLY CALL THE CLOSE OVERLAY HOOK IF THE SCREEN IS UNDER 1000 PIXELS 
  const handleHideBanner = () => {
    setShowFilters(false);
  };

  useOnClickOutside(filtersRef, handleHideBanner);


  const handleCategoryChange = (category) => {
    let updatedCategories;
    if (categoryFilters.includes(category)) {
      updatedCategories = categoryFilters.filter(c => c !== category);
    } else {
      updatedCategories = [...categoryFilters, category];
    }
    setCategoryFilters(updatedCategories); // Update context state
  
    // Update URL parameters
    if (updatedCategories.length > 0) {
      searchParams.set('categories', updatedCategories.join(','));
    } else {
      searchParams.delete('categories');
    }
    searchParams.delete('page'); // Always reset the page parameter
  
    //THIS CODE CHECKS HENRY
    // Check if the current URL is not the base URL (with or without search params)
    const isNotBasePage = location.pathname !== "/";
    const savedLocation = sessionStorage.getItem('savedLocation')
  
    // Only navigate back to the base URL if currently not on a base page
    if (isNotBasePage) {
      // Prepare new search parameters for navigation
      const newSearchParams = new URLSearchParams(searchParams.toString());
      if (savedLocation) {
        newSearchParams.set('location', savedLocation);
      }
      navigate('/?' + newSearchParams.toString());
    } else {
      // If on a base page, just update the search parameters without navigating
      setSearchParams(searchParams);
    }
    // END OF HENRY CHECKING
  
    Scroll();
    filtersRef.current.scrollTop = 0;
  
    setTimeout(() => {
      if (seeMoreStores) {
        setSeeMoreStores(false);
      }
    }, 300);
  };

  const handleStoreChange = (store) => {
    let updatedStores;
    if (storeFilters.includes(store)) {
        updatedStores = storeFilters.filter(s => s !== store);
    } else {
        updatedStores = [...storeFilters, store];
        // Track store click on Google Analytics
        trackEvent('select_store', {
          'event_category': 'Store Clicks',
          'store_name': store,
          'value': 1,
          'send_to': 'G-W17DEQW0DE'
        });
    }
    setStoreFilters(updatedStores); 

    // Update URL
    if (updatedStores.length > 0) {
        // If there are selected stores, set the parameter
        searchParams.set('stores', updatedStores.join(','));
    } 
    
    else {
        // If no stores are selected, delete the parameter
        searchParams.delete('stores');
    }
    searchParams.delete('page');
    // THIS CODE CHECKS HENRY
    // Check if the current URL is not the base URL (with or without search params)
    const isNotBasePage = location.pathname !== "/";
    const savedLocation = sessionStorage.getItem('savedLocation')

    // Only navigate back to the base URL if currently not on a base page
    if (isNotBasePage) {
      // Prepare new search parameters for navigation
      const newSearchParams = new URLSearchParams(searchParams.toString());

      if (savedLocation) {
        newSearchParams.set('location', savedLocation);
      }
      navigate('/?' + newSearchParams.toString());
    } else {
      // If on a base page, just update the search parameters without navigating
      setSearchParams(searchParams);
    }
    // END OF HENRY CHECKING
     
    Scroll();
    filtersRef.current.scrollTop = 0;

    setTimeout(() => {
        if (seeMoreStores) {
            setSeeMoreStores(false); // Set see more stores to false if open
        }
    }, 300); // A timeout of 0 ms delays the execution until after the current call stack has cleared

    
};



  function StoresCategories({ title, items, seeMore, setSeeMore, handleChange, selectedItems }) {

    const sortedItems = [...items].sort((a, b) => {
      const aSelected = selectedItems.includes(a);
      const bSelected = selectedItems.includes(b);
      if (aSelected && !bSelected) {
        return -1;
      }
      if (!aSelected && bSelected) {
        return 1;
      }
      return 0;
    });

    return (
      <div className={styles.subcontainer}>
        <div className={styles.subheader}>
          <div className={styles.subheadertext}>{title}&nbsp;</div>
          <div className={styles.counter}>&nbsp;({items.length})</div>
        </div>
        <>
          {sortedItems.slice(0, seeMore ? sortedItems.length : 5).map((item) => (
            <div key={item}>
              <div className={styles.selection}>
                <input 
                  type="checkbox"
                  id={`${title}-${item}`}
                  checked={selectedItems.includes(item)}
                  onChange={() => handleChange(item)}
                />
                <label htmlFor={`${title}-${item}`}>{item}</label>
              </div>
            </div>
          ))}
          {ToggleSeeMore(seeMore, setSeeMore, items.length)}
        </>
      </div>
    );
  }

  const ToggleSeeMore = (seeMoreState, setSeeMoreState, length) => {
    if (length > 6) {
      return (
        <div>
          <button className={styles.seemore} onClick={() => setSeeMoreState(!seeMoreState)}>
            {seeMoreState ? 'See Less' : 'See More'}
          </button>
        </div>
      );
    }
    return null;
  };

  function Featured({ title, items }) {
    return (
      <div className={styles.subcontainer}>
        <div className={styles.subheader}>
          <div className={styles.subheadertext}>{title}&nbsp;</div>
          <div className={styles.counter}>&nbsp;({items.length})</div>
        </div>
        <>
          {items.map((item, index) => (
            <div key={item} className={styles.selection}>
              <input 
                type="checkbox"
                id={`${title}-${item}`}
                checked={
                  index === 0 ? saleFilter : 
                  index === 1 ? newArrivalFilter : 
                  index === 2 ? deliverableFilter : false // Add deliverableFilter check
                }
                onChange={() => {
                  let urlSaleFilter = saleFilter;
                  let urlNewArrivalFilter = newArrivalFilter;
                  let urlDeliverableFilter = deliverableFilter; // Initialize deliverableFilter
  
                  if (index === 0) {
                    urlSaleFilter = !saleFilter;
                    setSaleFilter(urlSaleFilter);
                  } else if (index === 1) {
                    urlNewArrivalFilter = !newArrivalFilter;
                    setNewArrivalFilter(urlNewArrivalFilter);
                  } else if (index === 2) { // Add logic for DeliverableFilter
                    urlDeliverableFilter = !deliverableFilter;
                    setDeliverableFilter(urlDeliverableFilter);
                  }
                  // URL setting
                  searchParams.delete('page');
                  // Adding filters into the URL
                  if (urlSaleFilter) {
                    searchParams.set('sale', 'true');
                  } else {
                    searchParams.delete('sale');
                  }
  
                  if (urlNewArrivalFilter) {
                    searchParams.set('newArrival', 'true');
                  } else {
                    searchParams.delete('newArrival');
                  }
  
                  if (urlDeliverableFilter) { // Add Deliverable to URL
                    searchParams.set('deliverable', 'true');
                  } else {
                    searchParams.delete('deliverable');
                  }
                  
                  // HENRY REMOVAL CODE ON FEATURED FILTERS
                  const isNotBasePage = location.pathname !== "/";
                  const savedLocation = sessionStorage.getItem('savedLocation')
                  if (isNotBasePage) {
                    const newSearchParams = new URLSearchParams(searchParams);
                    if (savedLocation) {
                      newSearchParams.set('location', savedLocation);
                    }
                    navigate('/?' + newSearchParams.toString());
                  } else {
                    setSearchParams(searchParams)
                  }
                }}
              />
              <label htmlFor={`${title}-${item}`}>{item}</label>
            </div>
          ))}
        </>
      </div>
    );
  }
  
  let FeaturedItems = [];
  if (showSaleItemsInFilters) FeaturedItems.push("On Sale");
  if (showNewItemsInFilters) FeaturedItems.push("New Arrivals");
  if (showDeliverableItemsInFilters) FeaturedItems.push("Free Delivery"); 

  return (
    <>
      {showFilters && (
        <>
          <MobileBackdrop onClick={() => setShowFilters(false)} />
          <CloseBackdrop onClick={() => setShowFilters(false)} />
          <div ref={filtersRef} className={`${styles.container} ${showFilters ? styles.active : ''}`}>
            {/* Show N/A if distanceError is true */}
            {distanceError ? (
              <div className={styles.na}>
                N/A
              </div>
            ) : (
              <>
                {/* Render Featured only if not loading and items are present */}
                {!loading && FeaturedItems.length > 0 && (
                  <>
                    <Featured 
                      title="Featured"
                      items={FeaturedItems} 
                    />
                    <div className={styles.divider} />
                  </>
                )}
    
                {/* Loading Indicator when stores or categories are empty or still loading */}
                {loading ? (
                  <LoadingIndicator />
                ) : (
                  <>
                    <StoresCategories 
                      title="Stores" 
                      items={availableStores} 
                      seeMore={seeMoreStores}
                      setSeeMore={setSeeMoreStores}
                      handleChange={handleStoreChange}
                      selectedItems={storeFilters}
                    />
                    <div className={styles.divider} />
                    <StoresCategories 
                      title="Categories"
                      items={availableCategories} 
                      seeMore={seeMoreCategories}
                      setSeeMore={setSeeMoreCategories}
                      handleChange={handleCategoryChange}
                      selectedItems={categoryFilters}
                    />
                  </>
                )}
              </>
            )}
          </div>
        </>
      )}
    </>
  );

  
  
  
}

export default Filters;