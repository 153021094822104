import algoliasearch from 'algoliasearch/lite';

const algoliaClient = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID, process.env.REACT_APP_ALGOLIA_API_KEY_MAIN);
const index = algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_INDEX_NAME_MAIN);

let counter = 0;

export async function determineNeighborhood(userCoordinates) {
    counter ++;
    console.log("determineNeighborhood " + counter)
    try {
        
        const searchParams = {
            aroundLatLng: `${userCoordinates.latitude},${userCoordinates.longitude}`,
            hitsPerPage: 1, 
        };
        const response = await index.search('', searchParams);
        if (response.hits && response.hits.length > 0) {
            const closestHit = response.hits[0];
            return closestHit.neighborhood;
        }
        return null;
    } catch (error) {
        console.error('Error fetching nearest neighborhood:', error);
        throw error;
    }
}
