import React, { useContext } from 'react';
import { Scroll } from '../../../4_utils/h_ScrollToTop';
import { GlobalContext } from '../../../3_context/a_GlobalContext';
import { useSearchParams } from 'react-router-dom';
import styles from './b_LandingWidgets.module.css';
import go_icon from '../../../7_icons/go_icon.svg';
import Item from '../../../2_common/i_Item';


function ItemsWidget({ type, title, items, totalItems }) {

    const { setSaleFilter } = useContext(GlobalContext);
    const { setNewArrivalFilter } = useContext(GlobalContext);
    const { setDeliverableFilter } = useContext(GlobalContext);
    const { setStoreFilters } = useContext(GlobalContext);
    let [searchParams, setSearchParams] = useSearchParams();
    const { setCategoryFilters } = useContext(GlobalContext);
    const total = totalItems[title] || 0;

  
    // HANDLE A CLICK ON THE GO BUTTON BASED ON THE TYPE PROP
    const handleGoClick = () => {
      switch (type) {
        case "sale":
          setSaleFilter(true);
          searchParams.set('sale','true')
          setSearchParams(searchParams);
          Scroll();
          break;
        case "new":
          setNewArrivalFilter(true);
          searchParams.set('newArrival','true')
          setSearchParams(searchParams);
          Scroll();
          break;
        case "deliverable":
          setDeliverableFilter(true);
          searchParams.set('deliverable','true')
          setSearchParams(searchParams);
          Scroll();
          break;
        case "store":
          setStoreFilters(prevFilters => {
            if (!prevFilters.includes(title)) {
              return [...prevFilters, title];
            }
            return prevFilters;
          });
          searchParams.set('stores', title);
          searchParams.delete('query');
          searchParams.delete('categories');
          searchParams.delete('page');
          setSearchParams(searchParams);
          Scroll();
          break;
        case "category":
          setCategoryFilters(prevFilters => {
            if (!prevFilters.includes(title)) {
              return [...prevFilters, title];
            }
            return prevFilters;
          });
          searchParams.set('categories', title);
          searchParams.delete('query');
          searchParams.delete('stores');
          searchParams.delete('page');
          setSearchParams(searchParams);
          Scroll();
          break;
        default:
          Scroll();
          break;
      }
    };
    
  
    return (
      <div className={styles.subcontainer}>
        <div className={styles.banner}>
          <div className={styles.leftcontent} onClick={handleGoClick}>
            <div className={styles.header}>{title}</div>
            <div className={styles.leftsubheaderII}>{total} total </div>
            <img src={go_icon} alt="Previous" className={styles.goicon} />
          </div>
        </div>
        <div className={styles.items}>
          {items.map((item, index) => ( <Item item={item} key={index} setStoreFilters={setStoreFilters} /> ))}
        </div>
      </div>
    );
    }


    export default ItemsWidget;