import algoliasearch from 'algoliasearch/lite';
import { searchResultsPerPage, daysSinceArrival } from '../../App';

let counter = 0;

export async function fetchSearchResultsWithinRadius(query, coordinates, radius, storeFilters, categoryFilters, saleFilter, newArrivalFilter, deliverableFilter, page, sort, ) {
  counter++;
  console.log("fetchSearchResultsWithinRadius " + counter);
  console.log("Running fetchSearchResultsWithinRadius with", { query, coordinates, radius, storeFilters, categoryFilters, sort, saleFilter, newArrivalFilter, deliverableFilter });

  let algoliaClient;
  let index;

  // Determine which Algolia client and index to use based on the sort state
  if (sort === "Sort") {
    algoliaClient = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID, process.env.REACT_APP_ALGOLIA_API_KEY_MAIN);
    index = algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_INDEX_NAME_MAIN);
  } else if (sort === "Price: Low to High") {
    algoliaClient = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID, process.env.REACT_APP_ALGOLIA_API_KEY_SORT_CHEAP);
    index = algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_INDEX_NAME_SORT_CHEAP);
  } else if (sort === "Price: High to Low") {
    algoliaClient = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID, process.env.REACT_APP_ALGOLIA_API_KEY_SORT_EXPENSIVE);
    index = algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_INDEX_NAME_SORT_EXPENSIVE);
  }

  try {
    // Construct facet filters for Algolia
    const storeFacets = storeFilters.map(filter => `store:${filter}`);
    const categoryFacets = categoryFilters.map(filter => `category:${filter}`);
    let facetFilters = [];

    // Group store and category filters
    if (storeFacets.length > 0) facetFilters.push(storeFacets); // Each store filter is OR'ed together
    if (categoryFacets.length > 0) facetFilters.push(categoryFacets); // Each category filter is OR'ed together

    // Add isOnSale filter if saleFilter is true
    if (saleFilter) {
      facetFilters.push([`isOnSale:true`]);
    }

    // Add isDeliverable filter if deliverableFilter is true
    if (deliverableFilter) {
      facetFilters.push([`isDeliverable:true`]);
    }

    // Add new arrivals filter if newArrivalFilter is true
    if (newArrivalFilter) {
      const daysAgo = Math.floor(Date.now() / 1000) - (daysSinceArrival * 24 * 60 * 60);
      const numericFilters = [`timestamp > ${daysAgo}`];
      
      const response = await index.search(query, {
        hitsPerPage: searchResultsPerPage,
        page,
        aroundLatLng: `${coordinates.latitude},${coordinates.longitude}`, // Geolocation parameter for Algolia
        aroundRadius: Math.round(radius * 1609.34), // Convert miles to meters if needed
        facetFilters: facetFilters.length > 0 ? facetFilters : [], // Pass the structured facetFilters to Algolia
        numericFilters: numericFilters.length > 0 ? numericFilters : [], // Pass the numericFilters for new arrivals
      });

      return { items: response.hits, total: response.nbHits };
    } else {
      const response = await index.search(query, {
        hitsPerPage: searchResultsPerPage,
        page,
        aroundLatLng: `${coordinates.latitude},${coordinates.longitude}`, // Geolocation parameter for Algolia
        aroundRadius: Math.round(radius * 1609.34), // Convert miles to meters if needed
        facetFilters: facetFilters.length > 0 ? facetFilters : [], // Pass the structured facetFilters to Algolia
      });

      return { items: response.hits, total: response.nbHits };
    }
  } catch (error) {
    console.error(`Error fetching search results within radius`, error);
    return { items: [], total: 0 };
  }
}
