export function formatStoreHours(storehours) {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const today = new Date().getDay();
    const todayDayName = days[today];
    const pattern = new RegExp(`${todayDayName}:\\s*(\\d{1,2}(?::\\d{2})?\\s*(AM|PM)?\\s*-\\s*\\d{1,2}(?::\\d{2})?\\s*(AM|PM)|Closed)`, 'i');
    const match = storehours.match(pattern);
  
    if (match && match[1].toLowerCase() === 'closed') {
      return { hoursText: 'Closed Today', isClosed: true };
    } else if (match) {
      let hours = match[1];
      let times = hours.split('-').map(t => t.trim());
  
      times = times.map(time => {
        let formattedTime = time.replace(/\s*(AM|PM)/i, match => match.toLowerCase());
        return formattedTime.replace(/(\d)(AM|PM)/i, "$1$2").replace(/\s+/g, '');
      });
  
      if (times.length === 2 && !times[0].endsWith('am') && !times[0].endsWith('pm') && (times[1].endsWith('am') || times[1].endsWith('pm'))) {
        times[0] += times[1].slice(-2);
      }
      
      return { hoursText: `${times.join(' – ')}`, isClosed: false };
    } else {
      return { hoursText: 'Hours not available', isClosed: false };
    }
  }