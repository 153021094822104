import React, { useRef } from 'react';
import dropdown_icon from '../7_icons/dropdown_icon.svg';
import Backdrop from './o_MobileBackdrop';
import styles from './d_DropdownMenu.module.css';
import { useOnClickOutside } from '../4_utils/g_CloseOverlay';


function DropdownMenu({ leftmostIcon, currentValue, children, dropdownOpen, setDropdownOpen }) {
  const dropdownRef = useRef(null); 

  
  useOnClickOutside(dropdownRef, () => setDropdownOpen(false));

  return (
    <div className={styles.container} ref={dropdownRef} onClick={() => setDropdownOpen(!dropdownOpen)}>
      <i className={styles.leftmosticon}>
        {leftmostIcon}
      </i>
      <span className={styles.text}>{currentValue}</span>
      <i className={`${styles.dropdownicon} ${dropdownOpen ? styles.flip : ''}`}>
        <img src={dropdown_icon} alt="dropdown" />
      </i>
      {dropdownOpen && (
          <>
            <div className={`${styles.options} ${dropdownOpen ? styles.active : ''}`}>
                {children}
            </div>
            <Backdrop/>
          </>
      )}
    </div>
  );
}

export default DropdownMenu;
