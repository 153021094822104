import React from 'react';
import styles from './r_Backdrop.module.css'; // Import the CSS module

const Backdrop = () => {

    return (
        <div
            className={styles.backdrop}
        />
    );
};

export default Backdrop;
