import React, { useContext } from 'react';
import styles from './b_HighlightWidget.module.css';
import { GlobalContext } from '../../../3_context/a_GlobalContext';
import { neighborhoodImages, neighborhoodTaglines, storeImages, storeLogos, storeTaglines, storeSite, storeLocationLink, storeLocation, storeHours, storeContact } from '../../../App';
import about_icon from '../../../7_icons/about_icon.svg';
import phone_icon from '../../../7_icons/phone_icon.svg'; // Import your phone icon
import instagram_icon from '../../../7_icons/instagram_icon.svg'; // Import your Instagram icon
import location_icon from '../../../7_icons/location_icon.svg';
import { availableNeighborhoods } from '../../../App';
import { formatStoreHours } from '../../../4_utils/e_FormatStoreHours';

function HighlightWidget({ setShowStoreHighlight, setShowNeighborhoodHighlight }) {
    const { currentNeighborhood, currentLocationType, storeFilters } = useContext(GlobalContext);

    let highlight, image, location, hoursDetails, storeContent, contactElement;
    const isStore = storeFilters.length === 1 && storeTaglines[storeFilters[0]];
    const isNeighborhood = !isStore && availableNeighborhoods.includes(currentNeighborhood) && currentLocationType !== "Loading";
    let hasWebsite = false; 

    const openLink = (url) => {
        const target = window.innerWidth > 1200 ? '_blank' : '_self';
        window.open(url, target);
    };

    if (isStore) {
        const store = storeFilters[0];
        hasWebsite = storeSite.hasOwnProperty(store);
        highlight = storeTaglines[store];
        image = storeImages[store];
        location = storeLocation[store];
        const formattedHours = formatStoreHours(storeHours[store]);
        hoursDetails = formattedHours.hoursText;

        const contact = storeContact[store];
        if (contact.includes('@')) {
            // Instagram handle
            contactElement = (
                <div className={styles.contact} onClick={() => openLink(`https://instagram.com/${contact.substring(1)}`)}>
                    <img src={instagram_icon} className={styles.instagramicon} alt="Instagram" />
                    <div>instagram</div>
                </div>
            );
        } else {
            // Phone number
            contactElement = (
                <div className={styles.contact} onClick={() => openLink(`tel:${contact}`)}>
                    <img src={phone_icon} className={styles.phoneicon} alt="Phone" />
                    <div>{contact}</div>
                </div>
            );
        }

        storeContent = storeLogos[store] ? (
            <img src={storeLogos[store]} alt={`${store} logo`} className={styles.storelogo} onClick={() => openLink(storeSite[store])} />
        ) : (
            <div onClick={() => openLink(storeSite[store])} className={styles.storename}>{store}</div>
        );
    } else if (isNeighborhood) {
        highlight = neighborhoodTaglines[currentNeighborhood];
        image = neighborhoodImages[currentNeighborhood];
    } else {
        return null;
    }

    return (
        <div className={styles.totalcontainer}>
            {isStore && (
                <div className={styles.storedetailcontainer}>
                  {storeContent}
                    <div className={styles.storedetails}>
                        <div className={styles.storelocation} onClick={() => openLink(storeLocationLink[storeFilters[0]])}>
                            <img src={location_icon} className={styles.locationicon} alt="location" />
                            <div>{location}</div>
                        </div>
                        <div className={styles.divider}/>
                        <div>{hoursDetails}</div>
                        <div className={styles.divider}/>
                        {contactElement}
                        {hasWebsite && (
                            <>
                                <div className={styles.divider}/>
                                <div className={styles.site} onClick={() => openLink(storeSite[storeFilters[0]])}>
                                    site
                                    <img src={about_icon} className={styles.abouticon} alt="site" />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            )}
            <div className={styles.container}>
                <div className={styles.imagesubcontainer}>
                    <img src={image} className={styles.image} alt="highlight" />
                </div>
                <div className={styles.taglinesubcontainer}>
                    <div className={styles.tagline}>
                        {highlight}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HighlightWidget;