import React from 'react';

export const formatPrice = (minPrice, maxPrice, sale, styles) => {
  const formatIndividualPrice = (price, applySaleStyles) => {
    const [whole, cents] = price.toFixed(2).split('.');
    const itemPriceClass = applySaleStyles ? styles.saleItemPrice : styles.itemprice;
    const dollarSignClass = applySaleStyles ? styles.saleDollarSign : styles.dollarsign;
    const wholeClass = applySaleStyles ? styles.saleWhole : styles.whole;
    const centsClass = applySaleStyles ? styles.saleCents : styles.cents;
    return (
      <span className={itemPriceClass}>
        <span className={dollarSignClass}>$</span>
        <span className={wholeClass}>{whole}</span>
        <span className={centsClass}>{cents}</span>
      </span>
    );
};
    if (typeof minPrice === "number" && typeof maxPrice === "number" && minPrice >= 0 && maxPrice >= 0) {
      if (minPrice === maxPrice) {
        return <div className={styles.itempricecontainer}>{formatIndividualPrice(minPrice, false)}</div>;
      } else if (sale) {
        return (
          <div className={styles.itempricecontainer}>
            {formatIndividualPrice(minPrice, false)}
            <span className={styles.space}></span>
            {formatIndividualPrice(maxPrice, true)}
          </div>
        );
      } else {
        return (
          <div className={styles.itempricecontainer}>
            {formatIndividualPrice(minPrice, false)}
            <span className={styles.hyphen}>–</span>
            {formatIndividualPrice(maxPrice, false)}
          </div>
        );
      }
    }
};