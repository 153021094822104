// useCloseOnClickOutside.js or within your custom hooks file
import { useEffect } from 'react';

export function useOnClickOutside(ref, handler) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                handler();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, handler]); // Re-run if ref or handler changes
}
