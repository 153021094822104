// StoreBlock.js
import React from 'react';
import styles from './j_StoreBlock.module.css'; // Ensure this path matches your actual file structure

function StoreBlock({ storeName, storeBlockImage, style, onClick }) { // Add onClick prop
    return (
        <div className={styles.container} style={style} onClick={() => onClick(storeName)}> {/* Add click handler */}
            <div className={styles.storename}>{storeName}</div>
            <img className={styles.storeblockimage} src={storeBlockImage} alt={storeName} />
        </div>
    );
}

export default StoreBlock;
