import React, { useContext, useState, useEffect } from 'react';
import LandingWidgets from '../4_LandingWidgets/a_LandingWidgets';
import styles from './b_Body.module.css';

import HighlightWidget from '../3_HighlightWidget/a_HighlightWidget';
import SearchResults from '../5_SearchResults/a_SearchResults';
import { GlobalContext } from '../../../3_context/a_GlobalContext';
import LoadingIndicator from '../../../2_common/g_LoadingIndicator';

function Body() {
    const { initialSearch, 
            loading, 
            distanceError, 
            showTopBannerSearchBar,
            isMobile,
            setIsMobile
            } = useContext(GlobalContext);
    const [showLoadingMessage, setShowLoadingMessage] = useState(false);
    const [showNeighborhoodHighlight, setShowNeighborhoodHighlight] = useState(true);
    const [showStoreHighlight, setShowStoreHighlight] = useState(false);


    useEffect(() => {
        function handleResize() {
            setIsMobile(window.innerWidth < 800);
        }

        window.addEventListener('resize', handleResize);
        handleResize(); // Set the initial value

        return () => window.removeEventListener('resize', handleResize);
        // eslint-disable-next-line 
    }, []);

    useEffect(() => {
        let timeoutId;
        if (loading) {
            timeoutId = setTimeout(() => {
                setShowLoadingMessage(true);
            }, 300);
        } else {
            setShowLoadingMessage(false);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [loading]);

    useEffect(() => {
        let reloadTimeoutId;
        if (loading) {
            reloadTimeoutId = setTimeout(() => {
                window.location.reload();
            }, 8000);
        }
        return () => {
            if (reloadTimeoutId) {
                clearTimeout(reloadTimeoutId);
            }
        };
    }, [loading]);

    return (
        <div className={`${styles.container} ${isMobile && !showTopBannerSearchBar ? styles.togglemobilebottom : ''}`}>
            

            {loading ? (
                <div className={styles.loadingcontainer}>
                    {showLoadingMessage && <LoadingIndicator />}
                    {showLoadingMessage && <span className={styles.loadingtext}>Curating your local shopping experience...</span>}
                </div>
            ) : (
                <>
                    { distanceError ? (
                        <div className={styles.distanceerrortext}>
                            No nearby stores found. Expand your search radius or select a different neighborhood.
                        </div>
                    ) : (
                        <>
                            {(showNeighborhoodHighlight || showStoreHighlight) && (
                                <HighlightWidget
                                    setShowNeighborhoodHighlight={setShowNeighborhoodHighlight}
                                    setShowStoreHighlight={setShowStoreHighlight}
                                />
                            )}
                            {initialSearch === false ? (
                                <LandingWidgets/>
                            ) : (
                                <SearchResults />
                            )}
                        </>
                    )}
                </>
            )}

        </div>
    );
}

export default Body;
