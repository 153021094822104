import algoliasearch from 'algoliasearch';

// Initialize Algolia client
const algoliaClient = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID, process.env.REACT_APP_ALGOLIA_API_KEY_MAIN);
const index = algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_INDEX_NAME_MAIN);

export async function fetchSelectedItemDetails(objectID) {
    try {
        // Fetch the item details using the objectID
        const itemDetails = await index.getObject(objectID);
        return itemDetails; // This will be the item object from Algolia
    } catch (error) {
        console.error('Error fetching item details:', error);
        throw error;
    }
}
