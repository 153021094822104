// Utility to parse store hours into a structured object
export const parseStoreHours = (hoursString) => {
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const daysOpen = {};

  const daysHours = hoursString.split(', ');
  daysHours.forEach(dayHour => {
    const [dayPart, hoursPart] = dayHour.split(': ');
    const dayIndex = daysOfWeek.indexOf(dayPart);
    daysOpen[dayIndex] = hoursPart !== 'Closed';
  });

  return daysOpen;
};

const cleanStoreName = (storeName) => {
  // Remove "From " at the beginning of the string if it exists
  let cleanedName = storeName.replace(/^From /i, "");

  // Remove anything following " in the" (case-insensitive)
  cleanedName = cleanedName.replace(/ in .*/i, "");
  return cleanedName.trim();
   // Trim any leading/trailing whitespace
};

const getCurrentHourPST = () => {
  const timeZone = 'America/Los_Angeles'; // For both PST and PDT
  const now = new Date();
  const formatter = new Intl.DateTimeFormat('en-US', { hour: 'numeric', hour12: false, timeZone });
  return parseInt(formatter.format(now), 10);
};

// Function to get the delivery day based on the store hours and current time
export const getDeliveryDay = (storeName, storeHoursMap, currentTime = new Date()) => {
  // Create a date object for the current time in PST
  // Note: This assumes the server time is in UTC
  const pstTime = new Date(currentTime.getTime() - (7 * 60 * 60 * 1000)); // Subtract 7 hours to convert UTC to PST

  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const currentDay = pstTime.getDay(); // Day of week in PST
  const currentHour = getCurrentHourPST(); // Get the current hour in PST/PDT
  console.log("Current Hour in PST/PDT:", currentHour); // Debugging line

  const storeHours = parseStoreHours(storeHoursMap[cleanStoreName(storeName)]);

  // Check if it's after 3 PM PST
  if (currentHour >= 15) {
    // Logic for handling "Tomorrow" or finding the next available day
    const tomorrow = (currentDay + 1) % 7;
    if (storeHours[tomorrow]) {
      return "Tomorrow";
    } else {
      // Find the next available open day
      for (let i = 2; i <= 7; i++) {
        const nextDay = (currentDay + i) % 7;
        if (storeHours[nextDay]) {
          const nextDayDate = new Date(pstTime.getTime() + i * 24 * 60 * 60 * 1000);
          const dateStr = `${nextDayDate.getMonth() + 1}/${nextDayDate.getDate()}`;
          return `${daysOfWeek[nextDay]} (${dateStr})`;
        }
      }
    }
  } else {
    // If before 3 PM PST and the store is open today
    if (storeHours[currentDay]) {
      return "Today";
    } else {
      // Find the next available open day
      for (let i = 1; i <= 7; i++) {
        const nextDay = (currentDay + i) % 7;
        if (storeHours[nextDay]) {
          const nextDayDate = new Date(pstTime.getTime() + i * 24 * 60 * 60 * 1000);
          const dateStr = `${nextDayDate.getMonth() + 1}/${nextDayDate.getDate()}`;
          return `${daysOfWeek[nextDay]} ${dateStr}`;
        }
      }
    }
  }

  // Default return if no conditions are met
  return "No delivery date available";
};