import { loadStripe } from '@stripe/stripe-js';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

export const checkoutRedirectItemDetails = async (item, itemSpecifics, deliveryInfo, quantity) => {
  
    const amountInCents = Math.round(item.minPrice * 100);
    const stripe = await stripePromise;
    const currentUrl = window.location.href;
    const itemDetails = {
      itemName: item.item,
      amount: amountInCents,
      imageUrl: item.image,
      specifics: itemSpecifics,
      store: `From ${item.store} in ${item.neighborhood}`,
      delivery: deliveryInfo,
      quantity: quantity
    };
    try {
      const response = await fetch(process.env.REACT_APP_STRIPE_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          items: [itemDetails], // Your existing item details
          cancelUrl: currentUrl, // Send the captured URL to the backend
        }),
      });
      const { sessionId } = await response.json();
  
      // Redirect to Stripe Checkout
      const result = await stripe.redirectToCheckout({
        sessionId,
      });
      if (result.error) {
        console.error('Stripe Checkout error:', result.error);
      }
    } catch (error) {
      console.error('Error:', error);
    }
};
