import React, { createContext, useState, useEffect } from 'react';
import { determineNeighborhood } from '../5_services/a_Algolia/3_DetermineNeighborhood';
import { fetchFacetsWithinNeighborhood } from '../5_services/a_Algolia/1_FetchFacetsWithinNeighborhood';
import { fetchFacetsWithinRadius } from '../5_services/a_Algolia/2_FetchFacetsWithinRadius';
import { alphabeticalSort } from '../4_utils/d_AlphabeticalSort';
import { useLocation } from 'react-router-dom';

const defaultLoadingState = false;
const defaultDistanceError = false
const defaultLocation = "Select Location";
const defaultUserCoordinates = null;
const defaultDistance = 3;
const defaultCurrentLocationType = null;
const defaultCurrentNeighborhood = null;
const defaultAvailableCategories = [];
const defaultAvailableStores = [];
const defaultSearchResults = [];
const defaultQuery = "";
const defaultStoreFilters = [];
const defaultCategoryFilters = [];
const defaultInitialSearch = false;
const defaultSearchResultsCount = 0;
const defaultSearchResultsPage = 0;
const defaultSort = "Sort";
const defaultSaleFilter = false;
const defaultNewArrivalFilter = false;
const defaultDeliverableFilter = false;
const defaultCheckoutLoading = false;
const defaultShowFilters = false;
const defaultIsMobile = window.innerWidth < 800;
const defaultShowSaleItemsInFilters = true;
const defaultShowNewItemsInFilters = true;
const defaultShowDeliverableItemsInFilters = true;
const defaultShowTopBannerSearchBar = false;
const defaultCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];



const determineInitialLocationType = (location) => {
  const neighborhoods = ["The Richmond", "Outer Richmond", "Japantown"];
  if (neighborhoods.includes(location)) {
    return "Neighborhood";
  } else if (location === "Current Location") {
    return "Current Location";
  } else {
    return null; // or some default value for when the location doesn't match
  }
};

export const GlobalContext = createContext({
  loading: defaultLoadingState,
  distanceError: defaultDistanceError,
  currentLocation: defaultLocation,
  userCoordinates: defaultUserCoordinates,
  currentDistance: defaultDistance,
  currentLocationType: defaultCurrentLocationType,
  currentNeighborhood: defaultCurrentNeighborhood, 
  availableCategories: defaultAvailableCategories,
  availableStores: defaultAvailableStores,
  searchResults: defaultSearchResults,
  query: defaultQuery,
  storeFilters: defaultStoreFilters,
  categoryFilters: defaultCategoryFilters,
  initialSearch: defaultInitialSearch,
  searchResultsCount: defaultSearchResultsCount,
  searchResultsPage: defaultSearchResultsPage,
  sort: defaultSort,
  saleFilter: defaultSaleFilter,
  newArrivalFilter: defaultNewArrivalFilter,
  deliverableFilter: defaultDeliverableFilter,
  checkoutLoading: defaultCheckoutLoading,
  showFilters: defaultShowFilters,
  isMobile: defaultIsMobile,
  showSaleItemsInFilters: defaultShowSaleItemsInFilters,
  showNewItemsInFilters: defaultShowNewItemsInFilters,
  showDeliverableItemsInFilters: defaultShowDeliverableItemsInFilters,
  showTopBannerSearchBar: defaultShowTopBannerSearchBar,

});

export const GlobalProvider = ({ children }) => {
  const [loading, setLoading] = useState(defaultLoadingState)
  const [distanceError, setDistanceError] = useState(defaultDistanceError)
  
  const location = useLocation();
  
  
  // Parsing the URL for Query Parameters
  const searchParams = new URLSearchParams(location.search);
  const initialStoresFromURL = searchParams.get('stores') ? searchParams.get('stores').split(',') : defaultStoreFilters;
  const initialCategoriesFromURL = searchParams.get('categories') ? searchParams.get('categories').split(',') : defaultCategoryFilters;
  const initialLocationFromURL = searchParams.get('location') || defaultLocation;
  const [currentLocation, setCurrentLocation] = useState(initialLocationFromURL);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const urlLocation = searchParams.get('location');

    // Update only if there's a change needed.
    if (urlLocation && urlLocation !== currentLocation) {
        setCurrentLocation(urlLocation);
        setCurrentLocationType(determineInitialLocationType(urlLocation));
    }
}, [location.search, currentLocation]);

  
  const initialQuery = searchParams.get('query') || defaultQuery;
  const initialPageFromURL = searchParams.get('page') ? parseInt(searchParams.get('page'), 10) -1 : defaultSearchResultsPage;
  const initialSaleFilterFromURL = searchParams.get('sale') === 'true' || defaultSaleFilter; // Convert string to boolean
  const initialDeliverableFilterFromURL = searchParams.get('deliverable') === 'true' || defaultDeliverableFilter; // Convert string to boolean
  const initialNewArrivalFilterFromURL = searchParams.get('newArrival') === 'true' || defaultNewArrivalFilter; // Convert string to boolean

  // Setting states based on parsed URL
  
  const [currentLocationType, setCurrentLocationType] = useState(defaultCurrentLocationType);
  const [storeFilters, setStoreFilters] = useState(initialStoresFromURL);
  const [categoryFilters, setCategoryFilters] = useState(initialCategoriesFromURL);
  const [searchResultsPage, setSearchResultsPage] = useState(initialPageFromURL);
  const [saleFilter, setSaleFilter] = useState(initialSaleFilterFromURL)
  const [newArrivalFilter, setNewArrivalFilter] = useState(initialNewArrivalFilterFromURL)
  const [deliverableFilter, setDeliverableFilter] = useState(initialDeliverableFilterFromURL)
  const [query, setQuery] = useState(initialQuery);


  // Remaining states to set to default
  const [userCoordinates, setUserCoordinates] = useState(defaultUserCoordinates);
  const [currentDistance, setCurrentDistance] = useState(defaultDistance);
  const [currentNeighborhood, setCurrentNeighborhood] = useState(defaultCurrentNeighborhood);
  const [availableCategories, setAvailableCategories] = useState(defaultAvailableCategories);
  const [availableStores, setAvailableStores] = useState(defaultAvailableStores);
  const [searchResults, setSearchResults] = useState(defaultSearchResults);
  const [initialSearch, setInitialSearch] = useState(defaultInitialSearch);
  const [searchResultsCount, setSearchResultsCount] = useState(defaultSearchResultsCount);
  const [sort, setSort] = useState(defaultSort);
  const [checkoutLoading, setCheckoutLoading] = useState(defaultCheckoutLoading)
  


  // states associated with filters (formerly leftBanner) that are being uplifting
  // so that banner can be used in other routes (ItemDetails)
  const [showFilters, setShowFilters] = useState(defaultShowFilters)
  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);
  const [showSaleItemsInFilters, setShowSaleItemsInFilters] = useState(true);
  const [showNewItemsInFilters, setShowNewItemsInFilters] = useState(true);
  const [showDeliverableItemsInFilters, setShowDeliverableItemsInFilters] = useState(true);
  const [showTopBannerSearchBar, setShowTopBannerSearchBar] = useState(false);

  // CART STATE
  const [cartItems, setCartItems] = useState(defaultCartItems);




  // // To update states if someone changes the URL
  useEffect(() => {
    // setCurrentLocationType(initialCurrentLocationType);
    setStoreFilters(initialStoresFromURL);
    setCategoryFilters(initialCategoriesFromURL);
    setSearchResultsPage(initialPageFromURL);
    setSaleFilter(initialSaleFilterFromURL);
    setNewArrivalFilter(initialNewArrivalFilterFromURL);
    setDeliverableFilter(initialDeliverableFilterFromURL)
    setQuery(initialQuery);
    // eslint-disable-next-line
  }, [location.search]);


  // Global Cart





  // GET USER COORDINATES IF THEY SELECT CURRENT LOCATION
  // if the user selects Current Location, this asks for their permission to get location
  // if accepted, setUserCoorindates are populated and currentLocation is allowed to be "Current Location"
  // if denied or errored, current location is reset back to "Select Location"

  useEffect(() => {
    if (currentLocation === "Current Location" && !userCoordinates) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setUserCoordinates({ latitude, longitude });
          },
          (error) => {
            console.error("Geolocation permission denied or error occurred", error);
            if (error.code === error.PERMISSION_DENIED) {
              alert("Location permissions denied: Please allow location access from your browser settings or select a neighborhood");
              setCurrentLocation(defaultLocation);
              window.location.href = '/';
            }           
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
        setCurrentLocation(defaultLocation);
        
      }
    }
  }, [currentLocation, userCoordinates]);

  useEffect(() => {
    console.log(userCoordinates)
  }, [userCoordinates]);
  

  // DETERMINE THE LOCATION TYPE (EITHER SELECT LOCATION, UNSELECTED, CURRENT LOCATION OR LOADING)
  // Given fetching items is different for a neighborhood selection than a current location selection
  // we should centralize the state of locationType to optimize the use of this condition  
  useEffect(() => {
    const updateLocationType = () => {
      if (currentLocation === "Select Location") {
        setCurrentLocationType(null);
      } else if (currentLocation === "Current Location") {
        if (userCoordinates) {
          setCurrentLocationType("Current Location");
        } else {
          setCurrentLocationType("Loading");
        }
      } else {
        setCurrentLocationType("Neighborhood");
      }
    };
    updateLocationType();
  }, [currentLocation, userCoordinates]);

  // WE NEED TO KNOW THE USER'S CURRENT NEIGHBORHOOD EVEN IF THEY SELECT CURRENT LOCATION TO PROVIDE THEIR COORDINATES
  // this determines the user's neighborhood regardless of the location type
  // even if the user is using Current Location, they need a neighborhood to detemine which HighlightWidget to show
  useEffect(() => {
    const updateCurrentNeighborhood = async () => {
    if (currentLocationType === "Current Location" && userCoordinates) {
        try {
          const neighborhood = await determineNeighborhood(userCoordinates);
          setCurrentNeighborhood(neighborhood);
        } catch (error) {
          console.error("Failed to determine neighborhood", error);
        }
      } else if (currentLocationType === "Neighborhood" && currentLocation !== "Current Location") {
        setCurrentNeighborhood(currentLocation);
      } else {
        setCurrentNeighborhood(null);
      }
    };
    updateCurrentNeighborhood();
  }, [currentLocationType, userCoordinates, currentLocation]);
  

  // DETERMINE THE CATEGORIES AND STORES IN THE LEFT BANNER DEPENDING ON THE USER'S LOCATION
  // this sets the categories the user will see in the left banner
  // if Select Location is the current location it will return all unique categories from the index
  // if a neighborhood in the current location it will return all unique cateogories from that neighborhood
  // if current location is "current location" then 
  useEffect(() => {
    const fetchData = async (facet, setFunction) => {
      try {
        let uniqueItems = [];
        if (currentLocationType === "Current Location") {
          uniqueItems = await fetchFacetsWithinRadius(facet, userCoordinates, currentDistance);
          setCategoryFilters(initialCategoriesFromURL);
          setStoreFilters(initialStoresFromURL);
          setSearchResultsPage(initialPageFromURL);
        } else if (currentLocationType === "Neighborhood") {
          const facetFilter = [`neighborhood:${currentNeighborhood}`];
          uniqueItems = await fetchFacetsWithinNeighborhood(facet, facetFilter);
          // Need following setters otherwise this doesn't work - it rerenders page immediately this is what double renders featuredItems
          setCategoryFilters(initialCategoriesFromURL);
          setStoreFilters(initialStoresFromURL);
          setSearchResultsPage(initialPageFromURL);
 
        } else if (currentLocationType === null) {

          uniqueItems = await fetchFacetsWithinNeighborhood(facet);
          // Need following setters otherwise this doesn't work - it rerenders page immediately this is what double renders featuredItems
          setCategoryFilters(initialCategoriesFromURL);
          setStoreFilters(initialStoresFromURL);
          setSearchResultsPage(initialPageFromURL);
 
        }
        const sortedUniqueItems = alphabeticalSort(uniqueItems);
        setFunction(sortedUniqueItems);
      } catch (error) {
        console.error(`Error fetching ${facet}:`, error);
      }
    };
      fetchData("category", setAvailableCategories);
      fetchData("store", setAvailableStores);

  // eslint-disable-next-line
  }, [/*currentLocationType, userCoordinates,*/ currentDistance, currentNeighborhood]);


  // SET INITIAL SEARCH TO TRUE AND RESET IT TO FALSE IF FILTERS HAVE BEEN REMOVED
  useEffect(() => {
  
    // Condition to set initialSearch true
    if (!initialSearch && 
        (query !== defaultQuery || storeFilters.length > 0 || categoryFilters.length > 0 || saleFilter === true || newArrivalFilter === true || deliverableFilter === true)) {
      setInitialSearch(true);
    } 
    // Condition to reset initialSearch to false
    else if (
      initialSearch && 
      query === defaultQuery && 
      storeFilters.length === 0 && 
      categoryFilters.length === 0 &&
      saleFilter === false &&
      newArrivalFilter === false &&
      deliverableFilter === false
    ) {
      setInitialSearch(false);
      setSearchResultsCount(defaultSearchResultsCount);
    }
  }, [query, storeFilters, categoryFilters, initialSearch, saleFilter, newArrivalFilter, deliverableFilter]);

  // SET IS LOADING STATE TO BE USED IN BODY VIEW, BODY VIEW BANNER, AND LEFT BANNER
  useEffect(() => {
    // Conditions for setting the loading state to true
    if (currentLocationType === "Loading") {
      setLoading(true);
    } else {
      setLoading(false);
    }
  // eslint-disable-next-line
  }, [currentLocationType, availableCategories, availableStores]);

  // SET STATE TO DETERMINE WHEN TO SHOW "YOU ARE TOO FAR AWAY" ERROR MESSAGE TO BE USED IN BODY VIEW, BODY VIEW BANNER, AND LEFT BANNER
  useEffect(() => {
    let timeoutId;
    if (availableStores.length === 0 && currentLocation === "Current Location" && userCoordinates) {
        // Set a timeout for half a second
        timeoutId = setTimeout(() => {
            setDistanceError(true);
        }, 500);
    } else {
        // If conditions are not met, clear the timeout and reset distanceError
        clearTimeout(timeoutId);
        setDistanceError(false);
    }

    // Clean up the timeout when the component unmounts or dependencies change
    return () => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
    };
// eslint-disable-next-line
}, [availableStores, /*currentLocation,*/ userCoordinates, currentDistance]); 
  

// REMOVE STORE AND CATEGORORY FILTERS IF THEY EXIT THE LISTS OF AVAILABLT STORES AND CATEGORIES  
useEffect(() => {
  const updatedStoreFilters = storeFilters.filter(filter => availableStores.includes(filter));
  if (updatedStoreFilters.length !== storeFilters.length) {
    setStoreFilters(updatedStoreFilters);  // Update only if there's a change
  }
  const updatedCategoryFilters = categoryFilters.filter(filter => availableCategories.includes(filter));
  if (updatedCategoryFilters.length !== categoryFilters.length) {
    setCategoryFilters(updatedCategoryFilters);  // Update only if there's a change
  }
}, [availableStores, availableCategories, storeFilters, categoryFilters]);  // Dependencies

useEffect(() => {
  setSearchResultsPage(initialPageFromURL)
  // eslint-disable-next-line
}, [query, storeFilters, categoryFilters, availableCategories, availableStores]);


  // useEffect(() => {
  //   console.log("SEARCH RESULT STATES:")
  //   console.log('Updated query:', query);
  //   console.log('Store filters:', storeFilters);
  //   console.log('Category filters:', categoryFilters);
  //   console.log('Search Results:', searchResults)
  //   console.log('Initial Search:', initialSearch)
  //   console.log('Current Location:', currentLocation)
  //   console.log('Current Location Type:', currentLocationType)
  //   console.log('Distance:', currentDistance)
  // }, [/*query, storeFilters, categoryFilters, searchResults, initialSearch, currentDistance, currentLocation*/]);


  useEffect(() => {
    console.log(userCoordinates)
    // eslint-disable-next-line
  }, [userCoordinates]);

  useEffect(() => {
    console.log(deliverableFilter)
    // eslint-disable-next-line
  }, [deliverableFilter]);


return (
  <GlobalContext.Provider 
    value={{
      loading,
      setLoading,
      distanceError,
      currentLocation, 
      setCurrentLocation, 
      userCoordinates, 
      setUserCoordinates,
      currentDistance, 
      setCurrentDistance,
      currentLocationType, 
      currentNeighborhood,
      setCurrentNeighborhood,
      availableCategories,
      availableStores, 
      searchResults,
      setSearchResults,
      query,
      setQuery,
      storeFilters,
      setStoreFilters,
      categoryFilters,
      setCategoryFilters,
      initialSearch,
      setInitialSearch,
      searchResultsCount,
      setSearchResultsCount,
      searchResultsPage,
      setSearchResultsPage,
      sort,
      setSort,
      saleFilter,
      setSaleFilter,
      newArrivalFilter,
      setNewArrivalFilter,
      deliverableFilter,
      setDeliverableFilter,
      setCheckoutLoading,
      checkoutLoading, 
      showFilters,
      setShowFilters,
      isMobile, 
      setIsMobile,
      showSaleItemsInFilters,
      setShowSaleItemsInFilters,
      showNewItemsInFilters,
      setShowNewItemsInFilters,
      showDeliverableItemsInFilters,
      setShowDeliverableItemsInFilters,
      showTopBannerSearchBar,
      setShowTopBannerSearchBar,
      cartItems,
      setCartItems,
    }}>
    {children}
  </GlobalContext.Provider>
);
};