import React, { useEffect, useState } from 'react';
import styles from './p_MobileBackdrop.module.css'; // Import the CSS module

const MobileBackdrop = () => {
    const [fadeIn, setFadeIn] = useState(false);

    useEffect(() => {
        // Start the fade-in effect after component mounts
        setFadeIn(true);
    }, []);

    return (
        <div
            className={styles.backdrop} // Apply the class from the CSS module
            style={{ opacity: fadeIn ? 1 : 0 }} // Inline style for opacity fade-in effect
        />
    );
};

export default MobileBackdrop;
