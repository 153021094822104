import React, {useState} from 'react';
import styles from './b_BottomBanner.module.css';
import MobileBackdrop from '../2_common/o_MobileBackdrop';
import Backdrop from '../2_common/q_Backdrop';
import PrivacyPolicyOverlay from './s_PrivacyPolicyOverlay.js';
import AboutUsOverlay from './zc_AboutUs.js';

function BottomBanner() {

  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false)
  const [showAboutUs, setShowAboutUs] = useState(false)

  return (
    <div className={styles.container}>
      <div className={styles.buttonswrapper}>
        <div className={styles.privacypolicy} onClick={() => setShowPrivacyPolicy(true)}>
          Privacy
        </div>
        <div className={styles.aboutus} onClick={() => setShowAboutUs(true)}>
          About
        </div>
      </div>
      <div className={styles.rightcontent}>
        <div className={styles.righttext}>A proud partner of</div>
        <button className={styles.onerichmond} onClick={() => window.open('https://onerichmondsf.herokuapp.com/', '_blank')}>
          One Richmond
        </button>
      </div>
      {showPrivacyPolicy && (
      <>
        <PrivacyPolicyOverlay showPrivacyPolicy={showPrivacyPolicy} setShowPrivacyPolicy={setShowPrivacyPolicy}/>
        <Backdrop/>
        <MobileBackdrop/>
      </>

      )}
      {showAboutUs && (
        <>
          <AboutUsOverlay showAboutUs={showAboutUs} setShowAboutUs={setShowAboutUs}/>
          <Backdrop/>
          <MobileBackdrop/>
        </>
      )}
    </div>
  );
}

export default BottomBanner;
