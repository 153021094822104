import React from 'react';
import styles from './n_AboutCharm.module.css';
import logo_icon_thin from '../../../7_icons/logo_icon_thin.svg';
import heart_icon from '../../../7_icons/heart_icon.svg';
import explore_icon from '../../../7_icons/explore_icon.svg';

function AboutCharm() {

    return (
        <div className={styles.container}>
            <div className={styles.leftcontent}>
                <div className={styles.title}>
                    Join our mission of helping small businesses thrive!
                </div>
            </div>
            <div className={styles.rightcontent}>
                <div className={styles.texticoncombo}>
                    <img src={explore_icon} className={styles.economyicon} alt="Logo" />
                    <div className={styles.text}>
                        Explore local storefront offerings from a single search bar
                    </div>
                </div>
                <div className={styles.texticoncombo}>
                    <img src={logo_icon_thin} className={styles.logoicon} alt="Logo" />
                    <div className={styles.text}>
                        Perfect for same day needs or last minute housewarming gifts   
                    </div>
                </div>
                <div className={styles.texticoncombo}>
                    <img src={heart_icon} className={styles.hearticon} alt="Logo" />
                    <div className={styles.text}>
                        Shop local, reduce emissions, and support the community 
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutCharm;