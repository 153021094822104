import React from 'react';
import styles from './v_CloseBackdrop.module.css'; // Import the CSS module

const CloseBackdrop = () => {

    return (
        <div
            className={styles.backdrop}
        />
    );
};

export default CloseBackdrop;
