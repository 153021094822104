import React, { useRef, useEffect, useState, useContext } from 'react';
import styles from './l_EnlargedItem.module.css';
import location_icon from '../7_icons/location_icon.svg';
import store_icon from '../7_icons/store_icon.svg';
import { formatPrice } from '../4_utils/f_FormatPrice';
import { isMobile } from '../4_utils/b_MobileDeviceCheck.js';
import about_icon from '../7_icons/about_icon.svg';
import { GlobalContext } from '../3_context/a_GlobalContext';
import { Scroll } from '../4_utils/h_ScrollToTop';
import { storeLocationLink } from '../App';
import { useSearchParams } from 'react-router-dom';
import { trackEvent } from '../4_utils/i_GoogleAnalytics.js';
import { loadStripe } from '@stripe/stripe-js';
import { orderOnline, inStoreOnly } from '../App';
import close_icon from '../7_icons/close_icon.svg';


const EnlargedItem = ({ overlayState, closeOverlay }) => {
  const containerRef = useRef(null);
  const imageRef = useRef(null);
  const [bannerWidth, setBannerWidth] = useState(0);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
  const [precheckoutOverlay, setPrecheckoutOverlay] = useState(false);

  useEffect(() => {
    function handleClickOutside(event) {
      if (precheckoutOverlay) {
        // If the precheckout overlay is open, don't close the EnlargedItem overlay.
        return;
      }
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        closeOverlay();
      }
    }
  
    document.addEventListener("mousedown", handleClickOutside);
  
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [closeOverlay, precheckoutOverlay]); 

  const PrecheckoutOverlay = () => {
    // Create a Date object for the current time
    const now = new Date();

    // Utilize Intl.DateTimeFormat to get the time in 'America/Los_Angeles' timezone
    const formatter = new Intl.DateTimeFormat('en-US', {
        timeZone: 'America/Los_Angeles',
        hour: 'numeric',
        hour12: false,
    });

    // Extract the hour in PST/PDT timezone
    const pstHour = parseInt(formatter.format(now), 10);

    // Determine the delivery message based on the current PST/PDT hour
    const deliveryMessage = pstHour < 15 // Adjusted to check for before 4 PM PST/PDT
      ? "end of day today"
      : "tomorrow";

    return (
        <div className={styles.precheckoutoverlay}>
            <div className={styles.header}>You're Supporting {overlayState.item.store} in {overlayState.item.neighborhood}!</div>
            <div className={styles.divider} />
            <div className={styles.overlaybody}>After checkout, your item ({overlayState.item.item}) will be delivered straight from {overlayState.item.store} to your provided address by {deliveryMessage}. Delivery is only available around San Francisco.  </div>
            <div className={styles.checkoutbutton} onClick={handleCheckoutClick}>
                <div>Checkout</div>
            </div>
            <i className={styles.close} onClick={() => { setPrecheckoutOverlay(false)}}>
                <img src={close_icon} alt="close"/>
            </i>
        </div>
      );
    };
  


  const { setStoreFilters, setCheckoutLoading } = useContext(GlobalContext);

  const trackConversion = (url) => {
    window.gtag('event', 'conversion', {
      'send_to': 'AW-11426893111/KqeECPLwkogZELea4sgq',
      'event_callback': () => {
        if (url) window.location.href = url;
      }
    });
  };

  const updateBannerWidth = () => {
    if (imageRef.current) {
      setBannerWidth(imageRef.current.offsetWidth);
    }
  };

  const onImageLoad = () => {
    updateBannerWidth();
    setIsImageLoaded(true);
  };

  useEffect(() => {
    window.addEventListener('resize', updateBannerWidth);
    return () => window.removeEventListener('resize', updateBannerWidth);
  }, []);

  const handleStoreClick = () => {
    setStoreFilters([overlayState.item.store]);
    // Track store click
    trackEvent('select_store', {
      'event_category': 'Store Clicks',
      'store_name': overlayState.item.store,
      'value': 1,
      'send_to': 'G-W17DEQW0DE'
    });
    
    // Reset URL for navigation and put in clicked store
    searchParams.set('stores', overlayState.item.store)
    searchParams.delete('categories');
    searchParams.delete('page');
    searchParams.delete('query');
    setSearchParams(searchParams);

    Scroll();
  };

  const handleLocationClick = () => {
    const locationUrl = storeLocationLink[overlayState.item.store];
    if (locationUrl) {
      window.open(locationUrl, isMobile() ? '_self' : '_blank');
    }
  };

  const handleInfoClick = () => {
    // track if someone clicks on website from enlarged item overlay
    trackEvent('website_click', {
      'event_category': 'Click on website/Maps',
      'store_name': overlayState.item.store,
      'item_name' : overlayState.item.item,
      'value': 1,
      'send_to': 'G-W17DEQW0DE'
    });
    window.open(overlayState.item.info, isMobile() ? '_self' : '_blank');
  };

  const handlePurchaseClick = () => {
    setPrecheckoutOverlay(true); // Show the modal with delivery instructions
  };
  

  const handleCheckoutClick = async () => {
    // Continue using your existing tracking code
    setCheckoutLoading(true); // Set loading to true at the start

    // Schedule to set it back to false after 3 seconds
    setTimeout(() => {
        setCheckoutLoading(false);
    }, 2000);

    trackEvent('order_form_click', {
      'event_category': 'Click on order form',
      'store_name': overlayState.item.store,
      'item_name': overlayState.item.item,
      'value': 1,
      'send_to': 'G-W17DEQW0DE'
    });

    const amountInCents = Math.round(overlayState.item.minPrice * 100);
    console.log(amountInCents)
    console.log(overlayState.item.item)
    console.log(overlayState.imageSrc)

  
    // New Stripe Checkout logic
    const stripe = await stripePromise;
    const currentUrl = window.location.href;
    const itemDetails = {
      itemName: overlayState.item.item,
      amount: amountInCents, // Convert to smallest currency unit if needed
      imageUrl: overlayState.imageSrc
    };
  
    try {
      const response = await fetch(process.env.REACT_APP_STRIPE_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          item: itemDetails, // Your existing item details
          cancelUrl: currentUrl, // Send the captured URL to the backend
        }),
      });
      const { sessionId } = await response.json();
  
      // Redirect to Stripe Checkout
      const result = await stripe.redirectToCheckout({
        sessionId,
      });
      
      if (result.error) {
        console.error('Stripe Checkout error:', result.error);
      }
    } catch (error) {
      console.error('Error:', error);
    }
    trackConversion()
  };
  

  const handleOrderOnlineClick = () => {
    const url = overlayState.item.info; // Assuming item.info contains the URL
    if (isMobile()) {
      // Open in the same tab for mobile devices
      window.location.href = url;
    } else {
      // Open in a new tab for desktop
      window.open(url, '_blank');
    }
  };

  const handleInStoreOnlyClick = () => {
    const url = storeLocationLink[overlayState.item.store]; // Assuming item.info contains the URL
    if (isMobile()) {
      // Open in the same tab for mobile devices
      window.location.href = url;
    } else {
      // Open in a new tab for desktop
      window.open(url, '_blank');
    }
  };
  

  const showPrice = overlayState.item.minPrice || overlayState.item.maxPrice;
  //LOGIC TO DETERMINE WHICH TYPE OF PURCHASE BUTTON TO SHOW IF ANY
  const isOnlineOrderEnabled = orderOnline.includes(overlayState.item.store);
  const isInStoreOnly = inStoreOnly.includes(overlayState.item.store);
  
  // Determine which button to show based on the store's category and item details
  // Determine which button to show based on the store's category and item details
let buttonToShow;

  if (isInStoreOnly) {
    buttonToShow = 'inStoreOnly';
  } else if (isOnlineOrderEnabled) {
    buttonToShow = 'orderOnline';
  } else {
    // Check if price is not available (null, undefined, or empty string) and set to 'inStoreOnly' if so
    if (overlayState.item.minPrice == null || overlayState.item.minPrice === "") {
      buttonToShow = 'inStoreOnly';
    } else {
      // Continue with original logic if price is available
      const meetsCriteria = overlayState.item.minPrice === overlayState.item.maxPrice || overlayState.item.isOnSale;
      const priceWithinLimit = overlayState.item.minPrice <= 50; // Assuming minPrice is in cents

      buttonToShow = meetsCriteria && priceWithinLimit ? 'purchase' : 'inStoreOnly';
    }
  }




  return (
    <div className={styles.container}>
      {precheckoutOverlay && <PrecheckoutOverlay />}
      <div className={styles.subcontainer} ref={containerRef}>
        {isImageLoaded && (
          <div className={styles.topbanner} style={{ maxWidth: bannerWidth }}>
            <div>{overlayState.item.item}</div>
            {showPrice && (
              <>
                <div className={styles.topdivider}/>
                <div className={styles.itempricecontainer}>
                  {formatPrice(overlayState.item.minPrice, overlayState.item.maxPrice, overlayState.item.isOnSale, styles)}
                </div>
              </>
            )}
          </div>
        )}
        <img 
          ref={imageRef} 
          src={overlayState.imageSrc} 
          alt="Enlarged" 
          className={styles.enlargeditemimage} 
          onLoad={onImageLoad}
        />
        {isImageLoaded && (
          <div className={styles.bottombanner} style={{ maxWidth: bannerWidth }}>
            <div className={styles.bottombannersubcontainer}> 
            <div className={styles.bottombannercontainer} onClick={handleStoreClick}>
              <img src={store_icon} className={styles.storeicon} alt="store" />
              <div>store</div>
            </div>
            <div className={styles.bottomdivider}/>
            <div className={styles.bottombannercontainer} onClick={handleLocationClick}>
              <img src={location_icon} className={styles.locationicon} alt="location" />
              <div>locate</div>
            </div>
            
            {overlayState.item.info !== 'no website' && (
              <>
              <div className={styles.bottomdivider}/>
                <div className={styles.bottombannercontainer} onClick={handleInfoClick}>
                  <div>more details</div>
                  <img src={about_icon} className={styles.abouticon} alt="about" />
                </div>
              </>
            )}
            </div>
            {buttonToShow === 'orderOnline' && (
              <div className={styles.orderButton} onClick={handleOrderOnlineClick}>
                <div>Order Online</div>
              </div>
            )}
            {buttonToShow === 'inStoreOnly' && (
              <div className={styles.orderButton} onClick={handleInStoreOnlyClick}>
                <div>In Store Only</div>
              </div>
            )}
            {buttonToShow === 'purchase' && (
              <div className={styles.orderButton} onClick={handlePurchaseClick}>
                <div>Purchase</div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default EnlargedItem;
