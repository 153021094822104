import styles from './b_Explore.module.css';
import Body from '../2_Body/a_Body';
import BottomBanner from '../../../2_common/a_BottomBanner';
import React, {useState, useEffect, useRef} from 'react';
import close_icon from '../../../7_icons/close_icon.svg';
import TopBanner from '../../../2_common/w_TopBanner';

function Explore({ showSuccessOverlay }) {
  const [showOverlay, setShowOverlay] = useState(showSuccessOverlay);
  const overlayRef = useRef(null);

 
  useEffect(() => {
    // Function to handle click outside the overlay
    function handleClickOutside(event) {
      if (overlayRef.current && !overlayRef.current.contains(event.target)) {
        setShowOverlay(false);
        window.location.href = '/';
      }
    }
  
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showOverlay]); 

const successOverlay = (
  <div className={styles.purchaseoverlay} ref={overlayRef}>
    <div className={styles.header}>Your order has been received 🎉</div>
    <div className={styles.divider} />
    <div className={styles.overlaybody}>Our delivery team is en route. Keep an eye out for text updates!</div>
    <i className={styles.close} onClick={() => { setShowOverlay(false); window.location.href = '/'; }}>
      <img src={close_icon} alt="close"/>
    </i>
  </div>
);


return (

  <>
      {showOverlay && successOverlay}
      <div className={styles.container}>
      <TopBanner/>
      <div className={styles.body}>
        <Body/>
      </div>
    <BottomBanner/>
  </div>
    </>
  
);
}

export default Explore;